import React from 'react';

// import './DayPlanner.less';

const ModuleItems = (props) => {
    let radioName = `day${props.day}${props.slot}`,
        itemVal = `${props.session}--${props.currentItem.id}`,
        context = {day: props.day, slot: props.slot, item: props.currentItem},
        seatCheck = (props.seats.day1) ? (props.seats[`day${props.day}`][props.slot][props.session][props.currentItem.id]) : 1,
        seatAvailable = (parseInt(seatCheck) > 0),
        radioProps = {
            type: 'radio',
            disabled: ((!seatAvailable || props.isDayFull) ? 'disabled' : ''),
            name: radioName,
            id: radioName,
            value: itemVal
          };
    if (props.isDayFull) {
        radioProps.checked = false;
    }
        //inputRadio = props.currentItem.id === 'spacer' ? '' : (<input type='radio' checked={!props.isDayFull} disabled={props.isDayFull ? 'disabled' : ''} name={radioName} id={radioName} value={itemVal} onChange={(e) => props.clickHandler(e, context)}></input>);
    let inputRadio = props.currentItem.id === 'spacer' ? '' : (<input {...radioProps} onChange={(e) => props.clickHandler(e, context)}></input>);
    return (
        <>
        <td className={seatAvailable ? '': 'disable'}><ul className="module-items">{ (props.currentItem.subItems || []).map((subItem, idx) => (
            <li key={`${subItem.title}-${idx}` }>
                <h5 className="module-header">{subItem.title}</h5>
                <p className="module-desc">{subItem.desc}</p>
            </li>
            )
        )}</ul>
        </td>
        <td className="selected-option">
            {inputRadio}
        </td>
        </>
    );
}

export default ModuleItems;
