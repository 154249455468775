import React from 'react';

import ILabsBannerImage from 'assets/Rectangle -3@2x.png';

const ILabsBanner = () => (
    <div className="ilabs-banner-ctr">
    	{/* <img src={ILabsBannerImage} alt="Banner Image"/> */}
				<div className="ilabs-banner-content">
						<div className="ilabs-banner-title banner-header">Interactive Labs</div>
						<div className="ilabs-banner-text banner-text">
								Interactive Labs, specially designed for school teachers, are a space of learning and growing.
								{/* <a href="https://www.google.com">Download the full Interactive labs agenda</a> */}
						</div>
				</div>
		</div>
);

export default ILabsBanner;
