import React from 'react';

import './SummitOrganizers.less';

import Person1 from 'src/assets/Speakers/Ritu Khoda_founder.jpg';
import Person2 from 'src/assets/Speakers/SSamant.jpg';
import Person3 from 'src/assets/Speakers/agSingh.jpg';
import Person4 from 'src/assets/Speakers/team - priyam mehta@2x.png';
import Person5 from 'src/assets/Speakers/mXU6JaNC@2x.png';
import Person6 from 'src/assets/Speakers/2019-11-19@2x.png';
import Person7 from 'src/assets/Speakers/team -gopa trivedi@2x.png';
import Person8 from 'src/assets/Speakers/Likla_HR-e1563277626461@2x.png';
import Person9 from 'src/assets/Speakers/IMG_0273-e1563277831591@2x.png';

const orgTeam = [
	{ name: "Ritu Khoda", designation: "Founder-Director, Art1st", image: Person1 },
	{ name: "Sharmila Samant", designation: "Visual Artist & Advisor, Art1st", image: Person2 },
	{ name: "Amrita Gupta Singh", designation: "Art Historian and Curatorial Advisor, Art1st Foundation", image: Person3 },
	{ name: "Priyam Mehta", designation: "Artist Mentor, Art1st", image: Person4 },
	{ name: "Abhinav Yagnik", designation: "Artist Mentor, Art1st", image: Person5 },
	{ name: "Gopa Trivedi", designation: "Artist Mentor, Art1st", image: Person7 },
	{ name: "Wynona Alwyn", designation: "Program Coordinator", image: Person6 },
	{ name: "Likla Lall", designation: "Writer & Researcher", image: Person8 },
	{ name: "Rohina Thapar", designation: "Lead Designer", image: Person9 },
]

const orgTeamDom = orgTeam.map((item, index) => (
	<div className="organizing-teams-item">
		<div className="organizing-teams-avatar">
				<img src={item.image} alt="{item.name}" />
		</div>
		<div className="organizing-teams-role">
				<div className="bold-text">{item.name}</div>
				<div>{item.designation}</div>
		</div>
	</div>
));
const OrganizingTeams = () => (
	<div className="organizing-teams-ctr">
		<div className="organizing-teams-title">
				Organizing Team
		</div>
		<div className="organizing-teams-items">						
			{orgTeamDom}
		</div>
	</div>
);

export default OrganizingTeams;
