import React, { Component } from 'react';

import Modal from './Modal';
import './SpeakerList.less';

class PanelistsList extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false };
    };
    showModal() {
        this.setState({ show: true });
    };
    hideModal() {
        this.setState({ show: false });
    };
    render() {
        return (
            <div className="speakers-list-ctr">
                <div className="summit-speakers-heading">
                    Panelists
                </div>
                <div className="summit-speakers-content">
                    <div className="summit-speakers-items">
                        <div className="summit-speakers-item">
                            <div className="summit-speakers-avatar">
                                <img src="/assets/Group 90@2x.png" alt="Ramesh Pokhriyal" />
                            </div>
                            <div className="summit-speakers-title">
                                Ramesh Pokhriyal
                            </div>
                            <div className="summit-speakers-role">
                                Minister of Human Resource Development
                            </div>
                            <div className="text modal-ctr">
                                <div
                                    className="modal-action"
                                    onClick={this.showModal}
                                    role="button"
                                    onKeyDown={() => {}}
                                    tabIndex="0">
                                    Read Bio
                                </div>
                                <Modal
                                    show={this.state.show}
                                    handleClose={this.hideModal}
                                    avatar="/assets/Group 90@2x.png"
                                    title="Ramesh Pokhriyal"
                                    role="Minister of Human Resource Development">
                                    <div>
                                        Ramesh Pokhriyal 1 was first elected to the Uttar Pradesh Legislative Assembly from Karnaprayag constituency in 1991,
                                        and then later in 1993 and 1996 from the same constituency.
                                        In 1997 he was appointed to the position of Uttaranchal Development Minister.
                                        He was also Uttarakhand’s Chief Minister (from 2009 to 2011) and served as a member of Lok Sabha’s 17th session and the Chairman of the Assurance Committee.
                                        He is a representative of Hardware constituency in the Lok Sabha.
                                        He was the Member of Legislative Assembly of Uttar Pradesh and Uttarakhand for five consecutive terms from 1991 to 2012.
                                        He was the Member of Legislative Assembly of Uttar Pradesh.
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        <div className="summit-speakers-item">
                            <div className="summit-speakers-avatar">
                                <img src="/assets/Group 90@2x.png" alt="Ramesh Pokhriyal" />
                            </div>
                            <div className="summit-speakers-title">
                                Ramesh Pokhriyal
                            </div>
                            <div className="summit-speakers-role">
                                Minister of Human Resource Development
                            </div>
                            <div className="text modal-ctr">
                                <div
                                    className="modal-action"
                                    onClick={this.showModal}
                                    role="button"
                                    onKeyDown={() => {}}
                                    tabIndex="0">
                                    Read Bio
                                </div>
                                <Modal
                                    show={this.state.show}
                                    handleClose={this.hideModal}
                                    avatar="/assets/Group 90@2x.png"
                                    title="Ramesh Pokhriyal"
                                    role="Minister of Human Resource Development">
                                    <div>
                                        Ramesh Pokhriyal 2 was first elected to the Uttar Pradesh Legislative Assembly from Karnaprayag constituency in 1991,
                                        and then later in 1993 and 1996 from the same constituency.
                                        In 1997 he was appointed to the position of Uttaranchal Development Minister.
                                        He was also Uttarakhand’s Chief Minister (from 2009 to 2011) and served as a member of Lok Sabha’s 17th session and the Chairman of the Assurance Committee.
                                        He is a representative of Hardware constituency in the Lok Sabha.
                                        He was the Member of Legislative Assembly of Uttar Pradesh and Uttarakhand for five consecutive terms from 1991 to 2012.
                                        He was the Member of Legislative Assembly of Uttar Pradesh.
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        <div className="summit-speakers-item">
                            <div className="summit-speakers-avatar">
                                <img src="/assets/Group 90@2x.png" alt="Ramesh Pokhriyal" />
                            </div>
                            <div className="summit-speakers-title">
                                Ramesh Pokhriyal
                            </div>
                            <div className="summit-speakers-role">
                                Minister of Human Resource Development
                            </div>
                            <div className="text modal-ctr">
                                <div
                                    className="modal-action"
                                    onClick={this.showModal}
                                    role="button"
                                    onKeyDown={() => {}}
                                    tabIndex="0">
                                    Read Bio
                                </div>
                                <Modal
                                    show={this.state.show}
                                    handleClose={this.hideModal}
                                    avatar="/assets/Group 90@2x.png"
                                    title="Ramesh Pokhriyal"
                                    role="Minister of Human Resource Development">
                                    <div>
                                        Ramesh Pokhriyal 3 was first elected to the Uttar Pradesh Legislative Assembly from Karnaprayag constituency in 1991,
                                        and then later in 1993 and 1996 from the same constituency.
                                        In 1997 he was appointed to the position of Uttaranchal Development Minister.
                                        He was also Uttarakhand’s Chief Minister (from 2009 to 2011) and served as a member of Lok Sabha’s 17th session and the Chairman of the Assurance Committee.
                                        He is a representative of Hardware constituency in the Lok Sabha.
                                        He was the Member of Legislative Assembly of Uttar Pradesh and Uttarakhand for five consecutive terms from 1991 to 2012.
                                        He was the Member of Legislative Assembly of Uttar Pradesh.
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        <div className="summit-speakers-item">
                            <div className="summit-speakers-avatar">
                                <img src="/assets/Group 90@2x.png" alt="Ramesh Pokhriyal" />
                            </div>
                            <div className="summit-speakers-title">
                                Ramesh Pokhriyal
                            </div>
                            <div className="summit-speakers-role">
                                Minister of Human Resource Development
                            </div>
                            <div className="text modal-ctr">
                                <div
                                    className="modal-action"
                                    onClick={this.showModal}
                                    role="button"
                                    onKeyDown={() => {}}
                                    tabIndex="0">
                                    Read Bio
                                </div>
                                <Modal
                                    show={this.state.show}
                                    handleClose={this.hideModal}
                                    avatar="/assets/Group 90@2x.png"
                                    title="Ramesh Pokhriyal"
                                    role="Minister of Human Resource Development">
                                    <div>
                                        Ramesh Pokhriyal 4 was first elected to the Uttar Pradesh Legislative Assembly from Karnaprayag constituency in 1991,
                                        and then later in 1993 and 1996 from the same constituency.
                                        In 1997 he was appointed to the position of Uttaranchal Development Minister.
                                        He was also Uttarakhand’s Chief Minister (from 2009 to 2011) and served as a member of Lok Sabha’s 17th session and the Chairman of the Assurance Committee.
                                        He is a representative of Hardware constituency in the Lok Sabha.
                                        He was the Member of Legislative Assembly of Uttar Pradesh and Uttarakhand for five consecutive terms from 1991 to 2012.
                                        He was the Member of Legislative Assembly of Uttar Pradesh.
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        <div className="summit-speakers-item">
                            <div className="summit-speakers-avatar">
                                <img src="/assets/Group 90@2x.png" alt="Ramesh Pokhriyal" />
                            </div>
                            <div className="summit-speakers-title">
                                Ramesh Pokhriyal
                            </div>
                            <div className="summit-speakers-role">
                                Minister of Human Resource Development
                            </div>
                            <div className="text modal-ctr">
                                <div
                                    className="modal-action"
                                    onClick={this.showModal}
                                    role="button"
                                    onKeyDown={() => {}}
                                    tabIndex="0">
                                    Read Bio
                                </div>
                                <Modal
                                    show={this.state.show}
                                    handleClose={this.hideModal}
                                    avatar="/assets/Group 90@2x.png"
                                    title="Ramesh Pokhriyal"
                                    role="Minister of Human Resource Development">
                                    <div>
                                        Ramesh Pokhriyal 5 was first elected to the Uttar Pradesh Legislative Assembly from Karnaprayag constituency in 1991,
                                        and then later in 1993 and 1996 from the same constituency.
                                        In 1997 he was appointed to the position of Uttaranchal Development Minister.
                                        He was also Uttarakhand’s Chief Minister (from 2009 to 2011) and served as a member of Lok Sabha’s 17th session and the Chairman of the Assurance Committee.
                                        He is a representative of Hardware constituency in the Lok Sabha.
                                        He was the Member of Legislative Assembly of Uttar Pradesh and Uttarakhand for five consecutive terms from 1991 to 2012.
                                        He was the Member of Legislative Assembly of Uttar Pradesh.
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PanelistsList;
