import React, { Component } from 'react';
import './SupportedBy.less';


class SupportedBy extends Component {

 render() {
    const broughtTo = this.props.brought.map((item, index) => (
        <div key={item.id} className="sponsor-item" style={{ backgroundImage: `url(${item.image})` }}></div>
    ));

    const supportedBy = this.props.support.map((item, index) => (
        <div key={item.id} className="sponsor-item" style={{ backgroundImage: `url(${item.image})` }}></div>
    ));

    return (
        <div className="supportedBy">
            <hr></hr>
            <h3>Brought to you by</h3>
            <div className="sponsor-row">
                {broughtTo}
            </div>
            <h3>Supported by</h3>
            <div className="sponsor-row">
                {supportedBy}
            </div>
        </div>
       );
    }
}

export default SupportedBy;
