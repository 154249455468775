import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Select from './Select';
import Payment from '../../Payment/Payment';
import './ApplyForm.less';


class ApplyForm extends Component {
	
	constructor(props) {
        super(props);
        this.state = {
            guestName: '',
            mobile: '',
            designation: '',
            email: '',
            address: '',
            city: '',
            stateName: '',
            country: '',
            workshop1: false,
            workshop2: false,
            workshop3: false,
            workshop4: false,
            workshop5: false,
						workshop6: false,
						registrationId: "",
						saving: false,
						error: ""
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectInputChange = this.handleSelectInputChange.bind(this);
				this.handleSubmit = this.handleSubmit.bind(this);
		
				// this.registrationComplete = false;
				// this.registrationId = "";
	}
	

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSelectInputChange(obj) {
				const { key, value } = obj;
				this.setState({
						[key]: value
				});
				if (key === 'ilabsFullDay1') {
						this.setState({'ilabsMorningDay1': ''});
						this.setState({'ilabsEveningDay1': ''});
				} else if (key === 'ilabsFullDay2') {
						this.setState({'ilabsMorningDay2': ''});
						this.setState({'ilabsEveningDay2': ''});
				} else if (key === 'ilabsFullDay3') {
						this.setState({'ilabsMorningDay3': ''});
						this.setState({'ilabsEveningDay3': ''});
				}
	}

	validateForm() {
			return (this.state.guestName &&
					this.state.mobile &&
					this.state.designation &&
					this.state.email &&
					this.state.address &&
					this.state.city &&
					this.state.stateName &&
					this.state.country
			)
	}

	handleSubmit(event) {
		event.preventDefault();
		if(!this.validateForm()) {
			this.setState({error: "Please fill out all fields in the form."});
			return;
		} else {
			this.setState({error: ""});
		}

		this.setState({saving: true});
		const formUrl = 'https://us-central1-art1st-education-summit.cloudfunctions.net/registerAttendee';
		const formData = this.state;
		axios.post(
			formUrl,
			formData,
			{ headers: { 'content-type': 'application/json' } },
		).then((res) => {
			if(res && res.data) {
				this.setState({
					registrationId: res.data
				});
				window.scrollTo(0, 100);
			}
			console.log(res);
		}).catch((e) => {
			console.log(e);
			this.setState({saving: false});
		});
	}

	componentDidMount() {
		// this.registrationComplete = false;
		// this.registrationId = "";
	}

    render() {
    		const designationOptions = [
    				{ key: "minister", value: "Option 1" },
						{ key: "minister1", value: "Option 2" },
						{ key: "minister2", value: "Option 3" }
    		];
    		const countryOptions = [
                                 {
                                   "value": "Afghanistan",
                                   "key": "AF"
                                 },
                                 {
                                   "value": "Åland Islands",
                                   "key": "AX"
                                 },
                                 {
                                   "value": "Albania",
                                   "key": "AL"
                                 },
                                 {
                                   "value": "Algeria",
                                   "key": "DZ"
                                 },
                                 {
                                   "value": "American Samoa",
                                   "key": "AS"
                                 },
                                 {
                                   "value": "AndorrA",
                                   "key": "AD"
                                 },
                                 {
                                   "value": "Angola",
                                   "key": "AO"
                                 },
                                 {
                                   "value": "Anguilla",
                                   "key": "AI"
                                 },
                                 {
                                   "value": "Antarctica",
                                   "key": "AQ"
                                 },
                                 {
                                   "value": "Antigua and Barbuda",
                                   "key": "AG"
                                 },
                                 {
                                   "value": "Argentina",
                                   "key": "AR"
                                 },
                                 {
                                   "value": "Armenia",
                                   "key": "AM"
                                 },
                                 {
                                   "value": "Aruba",
                                   "key": "AW"
                                 },
                                 {
                                   "value": "Australia",
                                   "key": "AU"
                                 },
                                 {
                                   "value": "Austria",
                                   "key": "AT"
                                 },
                                 {
                                   "value": "Azerbaijan",
                                   "key": "AZ"
                                 },
                                 {
                                   "value": "Bahamas",
                                   "key": "BS"
                                 },
                                 {
                                   "value": "Bahrain",
                                   "key": "BH"
                                 },
                                 {
                                   "value": "Bangladesh",
                                   "key": "BD"
                                 },
                                 {
                                   "value": "Barbados",
                                   "key": "BB"
                                 },
                                 {
                                   "value": "Belarus",
                                   "key": "BY"
                                 },
                                 {
                                   "value": "Belgium",
                                   "key": "BE"
                                 },
                                 {
                                   "value": "Belize",
                                   "key": "BZ"
                                 },
                                 {
                                   "value": "Benin",
                                   "key": "BJ"
                                 },
                                 {
                                   "value": "Bermuda",
                                   "key": "BM"
                                 },
                                 {
                                   "value": "Bhutan",
                                   "key": "BT"
                                 },
                                 {
                                   "value": "Bolivia",
                                   "key": "BO"
                                 },
                                 {
                                   "value": "Bosnia and Herzegovina",
                                   "key": "BA"
                                 },
                                 {
                                   "value": "Botswana",
                                   "key": "BW"
                                 },
                                 {
                                   "value": "Bouvet Island",
                                   "key": "BV"
                                 },
                                 {
                                   "value": "Brazil",
                                   "key": "BR"
                                 },
                                 {
                                   "value": "British Indian Ocean Territory",
                                   "key": "IO"
                                 },
                                 {
                                   "value": "Brunei Darussalam",
                                   "key": "BN"
                                 },
                                 {
                                   "value": "Bulgaria",
                                   "key": "BG"
                                 },
                                 {
                                   "value": "Burkina Faso",
                                   "key": "BF"
                                 },
                                 {
                                   "value": "Burundi",
                                   "key": "BI"
                                 },
                                 {
                                   "value": "Cambodia",
                                   "key": "KH"
                                 },
                                 {
                                   "value": "Cameroon",
                                   "key": "CM"
                                 },
                                 {
                                   "value": "Canada",
                                   "key": "CA"
                                 },
                                 {
                                   "value": "Cape Verde",
                                   "key": "CV"
                                 },
                                 {
                                   "value": "Cayman Islands",
                                   "key": "KY"
                                 },
                                 {
                                   "value": "Central African Republic",
                                   "key": "CF"
                                 },
                                 {
                                   "value": "Chad",
                                   "key": "TD"
                                 },
                                 {
                                   "value": "Chile",
                                   "key": "CL"
                                 },
                                 {
                                   "value": "China",
                                   "key": "CN"
                                 },
                                 {
                                   "value": "Christmas Island",
                                   "key": "CX"
                                 },
                                 {
                                   "value": "Cocos (Keeling) Islands",
                                   "key": "CC"
                                 },
                                 {
                                   "value": "Colombia",
                                   "key": "CO"
                                 },
                                 {
                                   "value": "Comoros",
                                   "key": "KM"
                                 },
                                 {
                                   "value": "Congo",
                                   "key": "CG"
                                 },
                                 {
                                   "value": "Congo, The Democratic Republic of the",
                                   "key": "CD"
                                 },
                                 {
                                   "value": "Cook Islands",
                                   "key": "CK"
                                 },
                                 {
                                   "value": "Costa Rica",
                                   "key": "CR"
                                 },
                                 {
                                   "value": "Cote D'Ivoire",
                                   "key": "CI"
                                 },
                                 {
                                   "value": "Croatia",
                                   "key": "HR"
                                 },
                                 {
                                   "value": "Cuba",
                                   "key": "CU"
                                 },
                                 {
                                   "value": "Cyprus",
                                   "key": "CY"
                                 },
                                 {
                                   "value": "Czech Republic",
                                   "key": "CZ"
                                 },
                                 {
                                   "value": "Denmark",
                                   "key": "DK"
                                 },
                                 {
                                   "value": "Djibouti",
                                   "key": "DJ"
                                 },
                                 {
                                   "value": "Dominica",
                                   "key": "DM"
                                 },
                                 {
                                   "value": "Dominican Republic",
                                   "key": "DO"
                                 },
                                 {
                                   "value": "Ecuador",
                                   "key": "EC"
                                 },
                                 {
                                   "value": "Egypt",
                                   "key": "EG"
                                 },
                                 {
                                   "value": "El Salvador",
                                   "key": "SV"
                                 },
                                 {
                                   "value": "Equatorial Guinea",
                                   "key": "GQ"
                                 },
                                 {
                                   "value": "Eritrea",
                                   "key": "ER"
                                 },
                                 {
                                   "value": "Estonia",
                                   "key": "EE"
                                 },
                                 {
                                   "value": "Ethiopia",
                                   "key": "ET"
                                 },
                                 {
                                   "value": "Falkland Islands (Malvinas)",
                                   "key": "FK"
                                 },
                                 {
                                   "value": "Faroe Islands",
                                   "key": "FO"
                                 },
                                 {
                                   "value": "Fiji",
                                   "key": "FJ"
                                 },
                                 {
                                   "value": "Finland",
                                   "key": "FI"
                                 },
                                 {
                                   "value": "France",
                                   "key": "FR"
                                 },
                                 {
                                   "value": "French Guiana",
                                   "key": "GF"
                                 },
                                 {
                                   "value": "French Polynesia",
                                   "key": "PF"
                                 },
                                 {
                                   "value": "French Southern Territories",
                                   "key": "TF"
                                 },
                                 {
                                   "value": "Gabon",
                                   "key": "GA"
                                 },
                                 {
                                   "value": "Gambia",
                                   "key": "GM"
                                 },
                                 {
                                   "value": "Georgia",
                                   "key": "GE"
                                 },
                                 {
                                   "value": "Germany",
                                   "key": "DE"
                                 },
                                 {
                                   "value": "Ghana",
                                   "key": "GH"
                                 },
                                 {
                                   "value": "Gibraltar",
                                   "key": "GI"
                                 },
                                 {
                                   "value": "Greece",
                                   "key": "GR"
                                 },
                                 {
                                   "value": "Greenland",
                                   "key": "GL"
                                 },
                                 {
                                   "value": "Grenada",
                                   "key": "GD"
                                 },
                                 {
                                   "value": "Guadeloupe",
                                   "key": "GP"
                                 },
                                 {
                                   "value": "Guam",
                                   "key": "GU"
                                 },
                                 {
                                   "value": "Guatemala",
                                   "key": "GT"
                                 },
                                 {
                                   "value": "Guernsey",
                                   "key": "GG"
                                 },
                                 {
                                   "value": "Guinea",
                                   "key": "GN"
                                 },
                                 {
                                   "value": "Guinea-Bissau",
                                   "key": "GW"
                                 },
                                 {
                                   "value": "Guyana",
                                   "key": "GY"
                                 },
                                 {
                                   "value": "Haiti",
                                   "key": "HT"
                                 },
                                 {
                                   "value": "Heard Island and Mcdonald Islands",
                                   "key": "HM"
                                 },
                                 {
                                   "value": "Holy See (Vatican City State)",
                                   "key": "VA"
                                 },
                                 {
                                   "value": "Honduras",
                                   "key": "HN"
                                 },
                                 {
                                   "value": "Hong Kong",
                                   "key": "HK"
                                 },
                                 {
                                   "value": "Hungary",
                                   "key": "HU"
                                 },
                                 {
                                   "value": "Iceland",
                                   "key": "IS"
                                 },
                                 {
                                   "value": "India",
                                   "key": "IN"
                                 },
                                 {
                                   "value": "Indonesia",
                                   "key": "ID"
                                 },
                                 {
                                   "value": "Iran, Islamic Republic Of",
                                   "key": "IR"
                                 },
                                 {
                                   "value": "Iraq",
                                   "key": "IQ"
                                 },
                                 {
                                   "value": "Ireland",
                                   "key": "IE"
                                 },
                                 {
                                   "value": "Isle of Man",
                                   "key": "IM"
                                 },
                                 {
                                   "value": "Israel",
                                   "key": "IL"
                                 },
                                 {
                                   "value": "Italy",
                                   "key": "IT"
                                 },
                                 {
                                   "value": "Jamaica",
                                   "key": "JM"
                                 },
                                 {
                                   "value": "Japan",
                                   "key": "JP"
                                 },
                                 {
                                   "value": "Jersey",
                                   "key": "JE"
                                 },
                                 {
                                   "value": "Jordan",
                                   "key": "JO"
                                 },
                                 {
                                   "value": "Kazakhstan",
                                   "key": "KZ"
                                 },
                                 {
                                   "value": "Kenya",
                                   "key": "KE"
                                 },
                                 {
                                   "value": "Kiribati",
                                   "key": "KI"
                                 },
                                 {
                                   "value": "Korea, Democratic People'S Republic of",
                                   "key": "KP"
                                 },
                                 {
                                   "value": "Korea, Republic of",
                                   "key": "KR"
                                 },
                                 {
                                   "value": "Kuwait",
                                   "key": "KW"
                                 },
                                 {
                                   "value": "Kyrgyzstan",
                                   "key": "KG"
                                 },
                                 {
                                   "value": "Lao People'S Democratic Republic",
                                   "key": "LA"
                                 },
                                 {
                                   "value": "Latvia",
                                   "key": "LV"
                                 },
                                 {
                                   "value": "Lebanon",
                                   "key": "LB"
                                 },
                                 {
                                   "value": "Lesotho",
                                   "key": "LS"
                                 },
                                 {
                                   "value": "Liberia",
                                   "key": "LR"
                                 },
                                 {
                                   "value": "Libyan Arab Jamahiriya",
                                   "key": "LY"
                                 },
                                 {
                                   "value": "Liechtenstein",
                                   "key": "LI"
                                 },
                                 {
                                   "value": "Lithuania",
                                   "key": "LT"
                                 },
                                 {
                                   "value": "Luxembourg",
                                   "key": "LU"
                                 },
                                 {
                                   "value": "Macao",
                                   "key": "MO"
                                 },
                                 {
                                   "value": "Macedonia, The Former Yugoslav Republic of",
                                   "key": "MK"
                                 },
                                 {
                                   "value": "Madagascar",
                                   "key": "MG"
                                 },
                                 {
                                   "value": "Malawi",
                                   "key": "MW"
                                 },
                                 {
                                   "value": "Malaysia",
                                   "key": "MY"
                                 },
                                 {
                                   "value": "Maldives",
                                   "key": "MV"
                                 },
                                 {
                                   "value": "Mali",
                                   "key": "ML"
                                 },
                                 {
                                   "value": "Malta",
                                   "key": "MT"
                                 },
                                 {
                                   "value": "Marshall Islands",
                                   "key": "MH"
                                 },
                                 {
                                   "value": "Martinique",
                                   "key": "MQ"
                                 },
                                 {
                                   "value": "Mauritania",
                                   "key": "MR"
                                 },
                                 {
                                   "value": "Mauritius",
                                   "key": "MU"
                                 },
                                 {
                                   "value": "Mayotte",
                                   "key": "YT"
                                 },
                                 {
                                   "value": "Mexico",
                                   "key": "MX"
                                 },
                                 {
                                   "value": "Micronesia, Federated States of",
                                   "key": "FM"
                                 },
                                 {
                                   "value": "Moldova, Republic of",
                                   "key": "MD"
                                 },
                                 {
                                   "value": "Monaco",
                                   "key": "MC"
                                 },
                                 {
                                   "value": "Mongolia",
                                   "key": "MN"
                                 },
                                 {
                                   "value": "Montserrat",
                                   "key": "MS"
                                 },
                                 {
                                   "value": "Morocco",
                                   "key": "MA"
                                 },
                                 {
                                   "value": "Mozambique",
                                   "key": "MZ"
                                 },
                                 {
                                   "value": "Myanmar",
                                   "key": "MM"
                                 },
                                 {
                                   "value": "Namibia",
                                   "key": "NA"
                                 },
                                 {
                                   "value": "Nauru",
                                   "key": "NR"
                                 },
                                 {
                                   "value": "Nepal",
                                   "key": "NP"
                                 },
                                 {
                                   "value": "Netherlands",
                                   "key": "NL"
                                 },
                                 {
                                   "value": "Netherlands Antilles",
                                   "key": "AN"
                                 },
                                 {
                                   "value": "New Caledonia",
                                   "key": "NC"
                                 },
                                 {
                                   "value": "New Zealand",
                                   "key": "NZ"
                                 },
                                 {
                                   "value": "Nicaragua",
                                   "key": "NI"
                                 },
                                 {
                                   "value": "Niger",
                                   "key": "NE"
                                 },
                                 {
                                   "value": "Nigeria",
                                   "key": "NG"
                                 },
                                 {
                                   "value": "Niue",
                                   "key": "NU"
                                 },
                                 {
                                   "value": "Norfolk Island",
                                   "key": "NF"
                                 },
                                 {
                                   "value": "Northern Mariana Islands",
                                   "key": "MP"
                                 },
                                 {
                                   "value": "Norway",
                                   "key": "NO"
                                 },
                                 {
                                   "value": "Oman",
                                   "key": "OM"
                                 },
                                 {
                                   "value": "Pakistan",
                                   "key": "PK"
                                 },
                                 {
                                   "value": "Palau",
                                   "key": "PW"
                                 },
                                 {
                                   "value": "Palestinian Territory, Occupied",
                                   "key": "PS"
                                 },
                                 {
                                   "value": "Panama",
                                   "key": "PA"
                                 },
                                 {
                                   "value": "Papua New Guinea",
                                   "key": "PG"
                                 },
                                 {
                                   "value": "Paraguay",
                                   "key": "PY"
                                 },
                                 {
                                   "value": "Peru",
                                   "key": "PE"
                                 },
                                 {
                                   "value": "Philippines",
                                   "key": "PH"
                                 },
                                 {
                                   "value": "Pitcairn",
                                   "key": "PN"
                                 },
                                 {
                                   "value": "Poland",
                                   "key": "PL"
                                 },
                                 {
                                   "value": "Portugal",
                                   "key": "PT"
                                 },
                                 {
                                   "value": "Puerto Rico",
                                   "key": "PR"
                                 },
                                 {
                                   "value": "Qatar",
                                   "key": "QA"
                                 },
                                 {
                                   "value": "Reunion",
                                   "key": "RE"
                                 },
                                 {
                                   "value": "Romania",
                                   "key": "RO"
                                 },
                                 {
                                   "value": "Russian Federation",
                                   "key": "RU"
                                 },
                                 {
                                   "value": "RWANDA",
                                   "key": "RW"
                                 },
                                 {
                                   "value": "Saint Helena",
                                   "key": "SH"
                                 },
                                 {
                                   "value": "Saint Kitts and Nevis",
                                   "key": "KN"
                                 },
                                 {
                                   "value": "Saint Lucia",
                                   "key": "LC"
                                 },
                                 {
                                   "value": "Saint Pierre and Miquelon",
                                   "key": "PM"
                                 },
                                 {
                                   "value": "Saint Vincent and the Grenadines",
                                   "key": "VC"
                                 },
                                 {
                                   "value": "Samoa",
                                   "key": "WS"
                                 },
                                 {
                                   "value": "San Marino",
                                   "key": "SM"
                                 },
                                 {
                                   "value": "Sao Tome and Principe",
                                   "key": "ST"
                                 },
                                 {
                                   "value": "Saudi Arabia",
                                   "key": "SA"
                                 },
                                 {
                                   "value": "Senegal",
                                   "key": "SN"
                                 },
                                 {
                                   "value": "Serbia and Montenegro",
                                   "key": "CS"
                                 },
                                 {
                                   "value": "Seychelles",
                                   "key": "SC"
                                 },
                                 {
                                   "value": "Sierra Leone",
                                   "key": "SL"
                                 },
                                 {
                                   "value": "Singapore",
                                   "key": "SG"
                                 },
                                 {
                                   "value": "Slovakia",
                                   "key": "SK"
                                 },
                                 {
                                   "value": "Slovenia",
                                   "key": "SI"
                                 },
                                 {
                                   "value": "Solomon Islands",
                                   "key": "SB"
                                 },
                                 {
                                   "value": "Somalia",
                                   "key": "SO"
                                 },
                                 {
                                   "value": "South Africa",
                                   "key": "ZA"
                                 },
                                 {
                                   "value": "South Georgia and the South Sandwich Islands",
                                   "key": "GS"
                                 },
                                 {
                                   "value": "Spain",
                                   "key": "ES"
                                 },
                                 {
                                   "value": "Sri Lanka",
                                   "key": "LK"
                                 },
                                 {
                                   "value": "Sudan",
                                   "key": "SD"
                                 },
                                 {
                                   "value": "Surivalue",
                                   "key": "SR"
                                 },
                                 {
                                   "value": "Svalbard and Jan Mayen",
                                   "key": "SJ"
                                 },
                                 {
                                   "value": "Swaziland",
                                   "key": "SZ"
                                 },
                                 {
                                   "value": "Sweden",
                                   "key": "SE"
                                 },
                                 {
                                   "value": "Switzerland",
                                   "key": "CH"
                                 },
                                 {
                                   "value": "Syrian Arab Republic",
                                   "key": "SY"
                                 },
                                 {
                                   "value": "Taiwan, Province of China",
                                   "key": "TW"
                                 },
                                 {
                                   "value": "Tajikistan",
                                   "key": "TJ"
                                 },
                                 {
                                   "value": "Tanzania, United Republic of",
                                   "key": "TZ"
                                 },
                                 {
                                   "value": "Thailand",
                                   "key": "TH"
                                 },
                                 {
                                   "value": "Timor-Leste",
                                   "key": "TL"
                                 },
                                 {
                                   "value": "Togo",
                                   "key": "TG"
                                 },
                                 {
                                   "value": "Tokelau",
                                   "key": "TK"
                                 },
                                 {
                                   "value": "Tonga",
                                   "key": "TO"
                                 },
                                 {
                                   "value": "Trinidad and Tobago",
                                   "key": "TT"
                                 },
                                 {
                                   "value": "Tunisia",
                                   "key": "TN"
                                 },
                                 {
                                   "value": "Turkey",
                                   "key": "TR"
                                 },
                                 {
                                   "value": "Turkmenistan",
                                   "key": "TM"
                                 },
                                 {
                                   "value": "Turks and Caicos Islands",
                                   "key": "TC"
                                 },
                                 {
                                   "value": "Tuvalu",
                                   "key": "TV"
                                 },
                                 {
                                   "value": "Uganda",
                                   "key": "UG"
                                 },
                                 {
                                   "value": "Ukraine",
                                   "key": "UA"
                                 },
                                 {
                                   "value": "United Arab Emirates",
                                   "key": "AE"
                                 },
                                 {
                                   "value": "United Kingdom",
                                   "key": "GB"
                                 },
                                 {
                                   "value": "United States",
                                   "key": "US"
                                 },
                                 {
                                   "value": "United States Minor Outlying Islands",
                                   "key": "UM"
                                 },
                                 {
                                   "value": "Uruguay",
                                   "key": "UY"
                                 },
                                 {
                                   "value": "Uzbekistan",
                                   "key": "UZ"
                                 },
                                 {
                                   "value": "Vanuatu",
                                   "key": "VU"
                                 },
                                 {
                                   "value": "Venezuela",
                                   "key": "VE"
                                 },
                                 {
                                   "value": "Viet Nam",
                                   "key": "VN"
                                 },
                                 {
                                   "value": "Virgin Islands, British",
                                   "key": "VG"
                                 },
                                 {
                                   "value": "Virgin Islands, U.S.",
                                   "key": "VI"
                                 },
                                 {
                                   "value": "Wallis and Futuna",
                                   "key": "WF"
                                 },
                                 {
                                   "value": "Western Sahara",
                                   "key": "EH"
                                 },
                                 {
                                   "value": "Yemen",
                                   "key": "YE"
                                 },
                                 {
                                   "value": "Zambia",
                                   "key": "ZM"
                                 },
                                 {
                                   "value": "Zimbabwe",
                                   "key": "ZW"
                                 }
                               ];
    		const ilabsMorningOptions = [
    				{ key: "lab02", value: "Lab 02: Culture and Museum Learning" },
    				{ key: "lab03", value: "Lab 03: Creative Learning and Practice (CLaP)" },
    				{ key: "lab04", value: "Lab 04: Art1st's Partner-a-Master " },
    				{ key: "lab05", value: "Lab 05: UNBOX: An approach to Design Thinking"}
    		];
    		const ilabsAfternoonOptions = [
    				{ "key": "lab02", value: "Lab 02: Culture and Museum Learning"},
    				{ "key": "lab03", value: "Lab 03: Creative Learning and Practice (CLaP)"},
    				{ "key": "lab04", value: "Lab 04: Art1st's Partner-a-Master "},
    				{ "key": "lab05", value: "Lab 05: UNBOX: An approach to Design Thinking"}
    		];
    		const ilabsEveningOptions = [
    				{ "key": "lab01", value: "Lab 01: Build your Own Lab"}
    		];
        let regForm = (
            <form className="apply-form-ctr" onSubmit={this.handleSubmit}>
                <label>
                    <span>Name:</span>
                    <input
                        name="guestName"
                        type="text"
                        value={this.state.guestName}
                        onChange={this.handleInputChange} />
                </label>
                <br />
                <label>
                    <span>Mobile No.:</span>
                    <input
                        name="mobile"
                        type="text"
                        value={this.state.mobile}
                        onChange={this.handleInputChange} />
                </label>
                <br />
                <label>
                    <span>Designation:</span>
                    <Select customClass="add-left" label="Select Designation" name="designation" options={designationOptions} handleSelectInputChange={this.handleSelectInputChange} />
                </label>
                <br />
                <label>
                    <span>Email:</span>
                    <input
                        name="email"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleInputChange} />
                </label>
                <br />
                <label>
                    <span>Address:</span>
                    <textarea
                        value={this.state.address}
                        onChange={this.handleInputChange}
                        name="address" />
                </label>
                <br />
                <label>
                    <span>City:</span>
                    <input
                        name="city"
                        type="text"
                        value={this.state.city}
                        onChange={this.handleInputChange} />
                </label>
                <br />
                <label>
                    <span>State:</span>
                    <input
                        name="stateName"
                        type="text"
                        value={this.state.stateName}
                        onChange={this.handleInputChange} />
                </label>
                <br />
                <label>
                    <span>Country:</span>
                    <Select customClass="add-left" label="Select Country" name="country" options={countryOptions} handleSelectInputChange={this.handleSelectInputChange} />
                </label>
                <div className="session-form-ctr">
                		<h3 className="sub-heading">Choose your Interactive Lab</h3>
                		<p>Please select the Interactive Labs that you would like to attend. All Labs have a maximum of 2 sessions running over the day: Session I and Session II. Select the session of your preference.</p>
                		<p><b>LAB 01: Build your Own Lab</b></p>
                		<p>This lab will provide hands on training for teachers in cross-disciplinary collaborations between science-technology-arts.</p>
                		<p><b>LAB 02: Culture and Museum Learning</b></p>
                		<p>An exclusive session that introduces the pedagogy of museum and cultural learning to subject as well as art educators.</p>
                		<p><b>LAB 03: Creative Learning & Practice (CLaP)</b></p>
                		<p>Learning modules that engage with the architectural spaces in schools, and enhance cognitive and participatory skills in students.</p>
                		<p><b>LAB 04: Art1st's Partner-a-Master</b></p>
                		<p>Employing art as a pedagogical tool, this lab emphasizes on visual literacy, collective learning, and the role of the arts in cognitive and creative competencies.</p>
                		<p><b>LAB 05: UNBOX: An approach to Design Thinking</b></p>
                		<p>This lab provides an introduction to the theories of Design Thinking, focusing on innovation and problem solving. </p>
                		<p className="session-link">
                				<a href="/#ilabs">Check out all Interactive Labs</a>
                		</p>
                		<div className="session-day">
                				Day 1 | Dec 18
                		</div>
                		<div className="form-group">
                				<div className="text">
                						<div>Morning Session</div>
													 	<div className="light-text">10.00 am - 01.00 pm</div>
                				</div>
                				<label className="custom-label">
														<Select customParentClass="mobile-width" customClass="remove-left" label="Select Session" name="ilabsMorningDay1" options={ilabsMorningOptions} handleSelectInputChange={this.handleSelectInputChange} />
												</label>
                		</div>
                		<div className="form-group">
												<div className="text">
														<div>Evening Session </div>
														<div className="light-text">02.00 pm - 05.00 pm</div>
												</div>
												<label className="custom-label">
														<Select customParentClass="mobile-width" customClass="remove-left" label="Select Session" name="ilabsEveningDay1" options={ilabsAfternoonOptions} handleSelectInputChange={this.handleSelectInputChange} />
												</label>
										</div>
									 <div className="form-group">
											<div className="text">
													<div>Full Day Session </div>
													<div className="light-text">10.00 am - 05.00 pm</div>
											</div>
											<label className="custom-label">
													<Select customParentClass="mobile-width" customClass="remove-left" label="Select Session" name="ilabsFullDay1" options={ilabsEveningOptions} handleSelectInputChange={this.handleSelectInputChange} />
											</label>
									</div>
                		<div className="session-day">
                				Day 2 | Dec 19
                		</div>
                		<div className="form-group">
												<div className="text">
														<div>Morning Session</div>
														<div className="light-text">10.00 am - 01.00 pm</div>
												</div>
												<label className="custom-label">
														<Select customParentClass="mobile-width" customClass="remove-left" label="Select Session" name="ilabsMorningDay2" options={ilabsMorningOptions} handleSelectInputChange={this.handleSelectInputChange} />
												</label>
										</div>
										<div className="form-group">
													<div className="text">
															<div>Evening Session </div>
															<div className="light-text">02.00 pm - 05.00 pm</div>
													</div>
													<label className="custom-label">
															<Select customParentClass="mobile-width" customClass="remove-left" label="Select Session" name="ilabsEveningDay2" options={ilabsAfternoonOptions} handleSelectInputChange={this.handleSelectInputChange} />
													</label>
											</div>
										 <div className="form-group">
												<div className="text">
														<div>Full Day Session </div>
														<div className="light-text">10.00 am - 05.00 pm</div>
												</div>
												<label className="custom-label">
														<Select customParentClass="mobile-width" customClass="remove-left" label="Select Session" name="ilabsFullDay2" options={ilabsEveningOptions} handleSelectInputChange={this.handleSelectInputChange} />
												</label>
										</div>
                		<div className="session-day">
												Day 3 | Dec 20
										</div>
										<div className="form-group">
												<div className="text">
														<div>Morning Session</div>
														<div className="light-text">10.00 am - 01.00 pm</div>
												</div>
												<label className="custom-label">
														<Select customParentClass="mobile-width" customClass="remove-left" label="Select Session" name="ilabsMorningDay3" options={ilabsMorningOptions} handleSelectInputChange={this.handleSelectInputChange} />
												</label>
										</div>
										<div className="form-group">
													<div className="text">
															<div>Evening Session </div>
															<div className="light-text">02.00 pm - 05.00 pm</div>
													</div>
													<label className="custom-label">
															<Select customParentClass="mobile-width" customClass="remove-left" label="Select Session" name="ilabsEveningDay3" options={ilabsAfternoonOptions} handleSelectInputChange={this.handleSelectInputChange} />
													</label>
											</div>
										 <div className="form-group">
												<div className="text">
														<div>Full Day Session </div>
														<div className="light-text">10.00 am - 05.00 pm</div>
												</div>
												<label className="custom-label">
														<Select customParentClass="mobile-width" customClass="remove-left" label="Select Session" name="ilabsFullDay3" options={ilabsEveningOptions} handleSelectInputChange={this.handleSelectInputChange} />
												</label>
										</div>
										<h3 className="sub-heading">Choose your Child Art Workshop</h3>
										<p>Please select the Child Art Workshop that your or your organization’s children would like to to attend. All workshops are 2 hours in duration and can accommodate 30 children.</p>
                		<p className="session-link">
                				<a href="/#childartmuseum">Check out all Child Art Workshops</a>
                		</p>
                		<div className="session-day">
												Day 1 | Dec 18
										</div>
										<div className="form-group">
												<label className="container">
														Workshop 01
														<input type="checkbox" onChange={this.handleInputChange} name="workshop1" />
														<span className="checkmark"></span>
                        </label>
												<div className="session-details">
														<span>Morning Session | </span>
														<span className="light-text">11.00 am - 01.00 pm</span>
												</div>
										</div>
										<div className="form-group">
												<label className="container">Workshop 02
														<input type="checkbox" onChange={this.handleInputChange} name="workshop2" />
														<span className="checkmark"></span>
												</label>
												<div className="session-details">
														<span>Evening Session | </span>
														<span className="light-text">03.00 pm - 05.00 pm</span>
												</div>
										</div>
										<div className="session-day">
												Day 2 | Dec 19
										</div>
										<div className="form-group">
												<label className="container">Workshop 03
														<input type="checkbox" onChange={this.handleInputChange} name="workshop3" />
														<span className="checkmark"></span>
												</label>
												<div className="session-details">
														<span>Morning Session | </span>
														<span className="light-text">11.00 am - 01.00 pm</span>
												</div>
										</div>
										<div className="form-group">
												<label className="container">Workshop 04
														<input type="checkbox" onChange={this.handleInputChange} name="workshop4" />
														<span className="checkmark"></span>
												</label>
												<div className="session-details">
														<span>Evening Session | </span>
														<span className="light-text">03.00 pm - 05.00 pm</span>
												</div>
										</div>
										<div className="session-day">
												Day 3 | Dec 20
										</div>
										<div className="form-group">
												<label className="container">Workshop 05
														<input type="checkbox" onChange={this.handleInputChange} name="workshop5" />
														<span className="checkmark"></span>
												</label>
												<div className="session-details">
														<span>Morning Session | </span>
														<span className="light-text">11.00 am - 01.00 pm</span>
												</div>
										</div>
										<div className="form-group">
												<label className="container">Workshop 06
														<input type="checkbox" onChange={this.handleInputChange} name="workshop6" />
														<span className="checkmark"></span>
												</label>
												<div className="session-details">
														<span>Evening Session | </span>
														<span className="light-text">03.00 pm - 05.00 pm</span>
												</div>
										</div>
                </div>
								<Payment />
								<input type="submit" value={this.state.saving ? "SAVING..." : "SUBMIT"} className="form-submit-btn" disabled={this.state.saving ? "disabled": ""} />
								<p className={this.state.error ? "error show" : "error hide"}>{this.state.error}</p>
            </form>
		);
		let successForm = (
			<div>
				<div className="success-form">
					Thank you for registering. Your registration number is:<br />{this.state.registrationId}
				</div>
				<Payment />
			</div>
		);

		return this.state.registrationId ? successForm : regForm;
		
    }
}


export default ApplyForm;
