import React from 'react';

const SummitScheduleBanner = () => (
    <div className="summit-schedule-banner-ctr">
		<div className="summit-schedule-banner-content">
			<div className="summit-schedule-banner-title banner-header">Summit Schedule</div>
			<div className="summit-schedule-banner-text banner-text">
				Engage with keynote speakers, panelists, researchers, and experts on a range of topics and issues.
				<a href="https://firebasestorage.googleapis.com/v0/b/art1st-education-summit.appspot.com/o/2019%2Fschedule%2FAgenda%20Revised-02.png?alt=media&token=9ac3abb7-63f3-419a-819e-040a4b3ced2b">Download the full schedule</a>
			</div>
		</div>
	</div>
);

export default SummitScheduleBanner;
