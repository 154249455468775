import React from 'react';

import ChildArtMuseumBannerImage from 'assets/Rectangle 64@2x.png';

const ChildArtMuseumBanner = () => (
    <div className="child-art-museum-banner-ctr">
		<img src={ChildArtMuseumBannerImage} alt="Banner Image"/>
		<div className="child-art-museum-banner-content">
			<div className="heading banner-header">Child Art Museum</div>
			<div className="sub-heading banner-text">Celebrate our children's creativity through the Child Art Exhibit and Child Art Workshops.</div>
		</div>
	</div>
);

export default ChildArtMuseumBanner;
