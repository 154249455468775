const day1 = {
    morning: [
      { 
        id: "seminar-on-policy",
        name: "SEMINAR on POLICY",
        items: [
          { id: "general",
            name: "",
            subItems: [
              { title: "Introductory Speech", desc: "Contextualizing Creativity: Modules of Visual Thinking, Art Teaching and Learning" },
              { title: "Keynote Address", desc: "The Need of Reform in School Education in India: Research and Policy Implementation" },
              { title: "Panel Discussion I", desc: "Integrated Arts Pedagogy: Framing Policy and Curriculums for the Future" },
              { title: "Panel Discussion II", desc: "Zones of Exchange: Where Living Traditions and Contemporaneity Meet in Arts Education" },
            ]
          }
        ]
      },
      {
        id: "lab1",
        name: "LAB 1 | Art1st Partner-a-Master",
        items: [
          {
            id: "module1-full-day",
            name: "",
            isFullDay: true,
            subItems: [
              { title: "Module 1 (Full Day Session)", desc: "Future >><< Past: Creativity in the everyday with Sonal Jain" }
            ]
          },
          {
            id: "module2",
            name: "",
            subItems: [
              { title: "Module 2", desc: "Storytelling in Classrooms with Malvika Joshi" }
            ]
          }
        ]
      },
      {
        id: "lab2",
        name: "LAB 2 | Build your Own Lab",
        items: [
          {
            id: "module-full-day",
            name: "",
            isFullDay: true,
            subItems: [
              { title: "Module (Full Day Session)", desc: "Build your own Pollution Sensor" }
            ]
          }
        ]
      },
      {
        id: "lab3",
        name: "LAB 3 | Culture and Museum Learning",
        items: [
          {
            id: "module1",
            name: "",
            subItems: [
              { title: "Module 1", desc: "Museum of Idea and Objects" }
            ]
          }
        ]
      },
      {
        id: "lab4",
        name: "LAB 4 | Creative Learning and Practice (CLaP)",
        items: [
          {
            id: "module1",
            name: "",
            subItems: [
              { title: "Module 1", desc: "Ecological Sustainability & Climate Change" }
            ]
          }
        ]
      },
      {
        id: "lab5",
        name: "LAB 5 | UNBOX: An approach to Design Thinking",
        items: [
          {
            id: "module1",
            name: "",
            subItems: [
              { title: "Module 1", desc: "Acting on Empathy: Design Thinking for Educators by Quicksand" }
            ]
          }
        ]
      }
    ],
    afternoon: [
      { 
        id: "seminar-on-policy",
        name: "SEMINAR on POLICY",
        items: [
          { id: "general",
            name: "",
            subItems: [
              { title: "Paper Presentation I", desc: "Art Integrated Learning: Methods of Support through Education" },
              { title: "Paper Presentation II", desc: "The Constituency of Folk Art: Assimilation in Contemporary Art Education" },
              { title: "Best Practices", desc: "Project Sector 8, Akshara High School, Mumbai" },
              { title: "Workshop", desc: "Interplay between Creativity, Context and Innovation: Empirical Approaches" },
            ]
          }
        ]
      },
      {
        id: "spacer",
        items: [
          { id: "spacer" },
          { id: "spacer" }
        ]
      },
      {
        id: "spacer",
        items: [
          { id: "spacer" }
        ]
      },
      {
        id: "lab3",
        name: "LAB 3 | Culture and Museum Learning",
        items: [
          {
            id: "module2",
            name: "",
            subItems: [
              { title: "Module 2", desc: "Museum of Idea and Objects" }
            ]
          }
        ]
      },
      {
        id: "lab4",
        name: "LAB 4 | Creative Learning and Practice (CLaP)",
        items: [
          {
            id: "module2",
            name: "",
            subItems: [
              { title: "Module 2", desc: "Questioning Socio-cultural Perceptions" }
            ]
          }
        ]
      },
      {
        id: "lab5",
        name: "LAB 5 | UNBOX: An approach to Design Thinking",
        items: [
          {
            id: "module2",
            name: "",
            subItems: [
              { title: "Module 2", desc: "Acting on Empathy: Design Thinking for Educators by Quicksand" }
            ]
          }
        ]
      }
    ]
  };

  export default day1;