import React from 'react';

import OrganizersLogo from './OrganizersLogo';
import SummitOrganizersContent from './SummitOrganizersContent';
import Supporters from './Supporters';
import ExhibitionPartners from './ExhibitionPartners';
import InteractiveLabs from './InteractiveLabs';
import BookShopPartners from './BookShopPartners';
import PrintingPrPartner from './PrintingPrPartner';
import OrganizingTeams from './OrganizingTeams';

import './SummitOrganizers.less';

const SummitOrganizers = () => (
    <div className="summit-organizers-ctr">
				<OrganizersLogo />
				<SummitOrganizersContent />
				{/* <Supporters />
				<ExhibitionPartners />
				<InteractiveLabs />
				<BookShopPartners />
				<PrintingPrPartner /> */}
				<hr />
				<OrganizingTeams />
    </div>
);

export default SummitOrganizers;
