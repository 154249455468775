import React from 'react';

import './Banner.less';
import ImgHero from 'assets/Group 4@2x.png';

const Banner = () => (
    <div className="banner-ctr">
        <div className="banner-content">
            <div className="banner-content-main">
                <div className="logo">
                    <img src={ImgHero} alt="Art Education Summit" />
                </div>
                <div className="banner-text">
                    <ul>
                        <li>Let's change the narrative.</li>
                        <li>Let's put art back into education.</li>
                    </ul>
                    <div className="banner-content-sub">
                        <div className="show-in-mobile banner-sub-text-1">Lets discuss art education</div>
                        <div className="show-in-mobile banner-date-text">Dec 18-20, 2019</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Banner;
