import React from 'react';
import { Link } from "react-router-dom";

import './SummitSpeakers.less';
import Image1 from 'src/assets/Speakers/Arundhati Ghosh - ED IFA High res.jpg';
import Image2 from 'src/assets/Speakers/sv.jpg';
import Image3 from 'src/assets/Speakers/Dr Rizio B Yohannan.jpg';
import Image4 from 'src/assets/Speakers/NOBINA.G.jpg';
import Image5 from 'src/assets/Speakers/gaur.jpg';

const SummitSpeakers = () => (
    <div className="summit-speakers-ctr">
        <div className="summit-speakers-heading">
            Summit Speakers
        </div>
        <div className="summit-speakers-content">
            <div className="summit-speakers-items">
                <div className="summit-speakers-item">
                    <div className="summit-speakers-avatar" style={{backgroundImage: `url(${Image1})`}}>
                        {/* <img src={Image1} alt="Arundhati Ghosh" /> */}
                    </div>
                    <div className="summit-speakers-title">
                        Arundhati Ghosh
                    </div>
                    <div className="summit-speakers-role">
                        Director, IFA
                    </div>
                </div>
                <div className="summit-speakers-item">
                    <div className="summit-speakers-avatar" style={{backgroundImage: `url(${Image2})`}}>
                        {/* <img src={Image2} alt="Sophie Voegele" /> */}
                    </div>
                    <div className="summit-speakers-title">
                        Sophie Voegele
                    </div>
                    <div className="summit-speakers-role">
                        Zurich University of the Arts, Switzerland
                    </div>
                </div>
                <div className="summit-speakers-item">
                    <div className="summit-speakers-avatar" style={{backgroundImage: `url(${Image3})`}}>
                        {/* <img src={Image3} alt="Dr. Rizio B Yohannan" /> */}
                    </div>
                    <div className="summit-speakers-title">
                        Dr. Rizio B Yohannan
                    </div>
                    <div className="summit-speakers-role">
                        CEO, Marg Foundation
                    </div>
                </div>
                <div className="summit-speakers-item">
                    <div className="summit-speakers-avatar" style={{backgroundImage: `url(${Image4})`}}>
                        {/* <img src={Image4} alt="Ramesh Pokhriyal" /> */}
                    </div>
                    <div className="summit-speakers-title">
                        Nobina Gupta
                    </div>
                    <div className="summit-speakers-role">
                        Founder-Director, Disappearing Dialogues
                    </div>
                </div>
                <div className="summit-speakers-item">
                    <div className="summit-speakers-avatar" style={{backgroundImage: `url(${Image5})`}}>
                        {/* <img src={Image5} alt="Dr. Ramesh C. Gaur" /> */}
                    </div>
                    <div className="summit-speakers-title">
                        Dr. Ramesh C. Gaur
                    </div>
                    <div className="summit-speakers-role">
                    Dean and Director (Lib.&Inf.), 
                    Indira Gandhi National Centre for the Arts
                    </div>
                </div>
            </div>
            <div className="summit-speakers-note">
                <div className="bold-text">Read more about speakers, panelists and workshop facilitators.</div>
                <div className="text"><Link to="/speakers">Read More</Link></div>
            </div>
        </div>
    </div>
);

export default SummitSpeakers;
