import React from 'react';

import './Footer.less';
import ImgFB from 'assets/fb@2x.png';
import ImgTwitter from 'assets/twitter@2x.png';
import ImgInstagram from 'assets/insta@2x.png';
import ImgYT from 'assets/yt@2x.png';
import ImgArt1st from 'assets/Art1st.png';
import ImgArt1st01 from 'assets/Art1st Logo-01.png';
import IGNCALOGO from 'assets/IGNCA LOGO New@2x.png';
import CBSELOGO from 'assets/cbse-logo@2x.png';

const FooterMobile = () => (
    <div className="footer-ctr footer-ctr-mobile">
        <div className="footer-content">
            <div className="footer-content-left">
                <div className="footer-content-top">
                		<img src={ImgArt1st01} alt="Art1st Logo-01" className="artist-logo" />
                    <img src={ImgArt1st} alt="Art1st" />
                    <a href="www.art1st.co.in" target="_blank" rel="noopener noreferrer">www.art1st.co.in</a>
                </div>
                <div className="footer-content-bottom">
                    <a href="https://www.facebook.com/art1stIndia/" target="_blank"><img src={ImgFB} alt="Facebook" /></a>
                    <a href="https://www.youtube.com/channel/UCVpcSbA8ZuzN7oiIB_3ZQLw" target="_blank"><img src={ImgYT} alt="Youtube" /></a>
                    <a href="https://www.instagram.com/art1stindia" target="_blank"><img src={ImgInstagram} alt="Instagram" /></a>
                    <a href="https://www.twitter.com/art1stindia" target="_blank"><img src={ImgTwitter} alt="Twitter" /></a>
                </div>
            </div>
            <div className="footer-content-right">
            		<img src={IGNCALOGO} alt="IGNCA LOGO" />
            		<img src={CBSELOGO} alt="cbse logo" className="cbse-logo" />
            </div>
        </div>
    </div>
);

export default FooterMobile;
