import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ApplyForm from './ApplyForm/ApplyForm';
import RegisterBanner from './RegisterBanner';
import './RegistrationForm.less';

class RegistrationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formSubmitted: false,
        };
    }

    render() {
        const { formSubmitted } = this.state;
        return (
            <div className="registration-form-ctr">
            		<RegisterBanner />
                <div className="registration-form-text">
                    <div className="bold-text">Registration fees includes:</div>
                    Full Access to the 3-Day Seminar <br />
                    Free Participation in Interactive Labs <br />
                    Free Participation in Thematic Workshops <br />
                    Invite for the Child Art Exhibit <br />
                    Lunch at the venue for all 3 days
                </div>
                <ApplyForm />
            </div>
        );
    }
}


export default RegistrationForm;
