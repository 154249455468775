import React from 'react';

import './Address.less';
import LocationMap from 'assets/Screen Shot 2019-10-25 at 10.40.07 AM@2x.png';

const Address = () => (
    <div className="address-ctr">
        <div className="address-content">
            <div className="address-content-left">
                <a target="_blank" href="https://www.google.com/maps/dir//Indira+Gandhi+National+Centre+for+the+Arts,+11,+Man+Singh+Rd,+near+Andhra+Bhavan,+Rajpath+Area,+Central+Secretariat,+New+Delhi,+Delhi+110011/@28.6154758,77.1850364,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x390ce2c9af3dd147:0x6bf14eaeb73ba5e4!2m2!1d77.2200559!2d28.6154803">
										<img alt="location details map" src={LocationMap} />
								</a>
            </div>
            <div className="address-content-right">
                <div className="text">
                    Indira Gandhi National Centre for the Arts,<br/>
                    New Delhi, India
                </div>
                <div className="date-text">
                    Dec 18-20, 2019
                </div>
                {/* <div className="register-btn">
                    <a href="https://forms.gle/Xvoc3GmEkxkA5Cis6" target="_blank">REGISTER</a>
                </div> */}
            </div>
        </div>
    </div>
);

export default Address;
