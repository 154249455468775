import React from 'react';

import KitabCorner1 from 'src/assets/Main Banner-13@2x.png';
import KitabCorner2 from 'src/assets/Screen Shot 2019-11-18 at 8.54.22 PM@2x.png';
import KitabCorner3 from 'src/assets/Screen Shot 2019-11-18 at 9.08.42 PM@2x.png';
import KitabCorner4 from 'src/assets/Screen Shot 2019-11-18 at 9.10.33 PM@2x.png';
import KitabCorner5 from 'src/assets/Screen Shot 2019-11-18 at 9.14.25 PM@2x.png';
import KitabCorner6 from 'src/assets/Screen Shot 2019-11-18 at 9.16.59 PM@2x.png';

import Pub1 from 'assets/kitabPublishers/Art1st_Logo-01-White+Grey_Black BG@2x.png';
import Pub2 from 'assets/kitabPublishers/Marg Since 1946-01@2x.png';
import Pub3 from 'assets/kitabPublishers/DAG logo@2x.png';
import Pub4 from 'assets/kitabPublishers/Seagull Books@2x.png';
import Pub5 from 'assets/kitabPublishers/TaraBooksHLogo-01@2x.png';
import Pub6 from 'assets/kitabPublishers/Screen Shot 2019-11-18 at 8.50.39 PM@2x.png';
import './KitabCorner.less';
import { noAuto } from '@fortawesome/fontawesome-svg-core';

const data = [
	{bookImgURI: KitabCorner1, publisherImgURI: Pub1, title: "Art1st Books", desc: "Introducing children to the world of Indian Visual art through vibrant and interactive books.", bookURI: "https://firebasestorage.googleapis.com/v0/b/art1st-education-summit.appspot.com/o/2019%2Fcatalogues%2FArt1st%20Books%20Catalogue.pdf?alt=media&token=ffb396a4-d4fb-420c-b64d-bbdd04a6e80a"},
	{bookImgURI: KitabCorner2, publisherImgURI: Pub2, title: "MARG", desc: "Pioneering research in Indian art presented in iconic magazines and books.", bookURI: "https://www.marg-art.org/categories"},
	{bookImgURI: KitabCorner3, publisherImgURI: Pub3 , title: "DAG", desc: "An extensive catalogue of art books that can summarize India's art history.", bookURI: "http://dagworld.com/publications/"},
	{bookImgURI: KitabCorner4, publisherImgURI: Pub4 , title: "Seagull Books", desc: "Books with an eye to both exceptional content and radical design.", bookURI: "https://www.seagullbooks.org/"},
	{bookImgURI: KitabCorner5, publisherImgURI: Pub5 , title: "Tara Books", desc: "Handmade books of children's literature, photography, graphic novels, art & art education.", bookURI: "https://tarabooks.com/product-category/books/"},
	{bookImgURI: KitabCorner6, publisherImgURI: Pub6 , title: "Ektara", desc: "Books about imagination for children written in the many languages of India.", bookURI: "https://firebasestorage.googleapis.com/v0/b/art1st-education-summit.appspot.com/o/2019%2Fcatalogues%2FBooks%20Catalogue_Tara%20Books.pdf?alt=media&token=4c6ed320-ed5b-4447-b18f-1de414246851"}
];

const kitabItems = data.map((item, index) => {
	return (
		<div className="kitab-corner-item">
			<div className="kitab-corner-avatar" style={{backgroundImage: `url(${item.bookImgURI})`}}>
				<div className="kitab-corner-pub-avatar" style={{backgroundImage: `url(${item.publisherImgURI})`}}></div>
			</div>
			<div className="kitab-corner-item-title">{item.title}</div>
			<div className="kitab-corner-desc">{item.desc}</div>
			<div className="kitab-corner-link"><a href={item.bookURI} target="_blank">View Catalogue</a></div>
		</div>
	);
})

const KitabCorner = () => (
    <div className="kitab-corner-ctr">
        <div className="kitab-corner-title">
            Kitab Corner
        </div>
        <div className="kitab-corner-content">
        	<div className="kitab-corner-items">
				{kitabItems}
        	</div>
            <div className="text" style={{width: '100%'}}>
								<p>
										Our book partners bring to you a wide range of mesmerizing publications from their catalogues at special prices!
								</p>
            </div>
        </div>
    </div>
);

export default KitabCorner;
