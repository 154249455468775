import React from 'react';

import Speaker1 from 'src/assets/Speakers/sr.jpg';
import Speaker2 from 'src/assets/Speakers/NOBINA.G.jpg';
import Speaker3 from 'src/assets/Speakers/headshotjigyasapassport.jpg';
import Speaker4 from 'src/assets/Speakers/sbn.jpg';
import Speaker5 from 'src/assets/Speakers/sv.jpg';
import Speaker6 from 'src/assets/Speakers/PennyHay.jpg';
import Speaker7 from 'src/assets/Speakers/Dilbur-Parakh-Profile-Picture.jpg';
import Speaker8 from 'src/assets/Speakers/lv.jpg';
import Speaker9 from 'src/assets/Speakers/Roobina-Karode.jpg';
import Speaker10 from 'src/assets/Speakers/Tejshvi-Jain.jpg';
import Speaker11 from 'src/assets/Speakers/mk.jpg';
import Speaker12 from 'src/assets/Speakers/SSamant.jpg';
import Speaker13 from 'src/assets/Speakers/Avni-Sethi.jpg';
import Speaker14 from 'src/assets/Speakers/Poulomi-Das.jpg';
import Speaker15 from 'src/assets/Speakers/Sreya_TinkerLabs.png';
import Speaker16 from 'src/assets/Speakers/Avani_Tinkerlabs.png';

const DayTwoContent = () => (
		<div className="day-content-ctr">
				<div className="day-content">
						<div className="day-intro-content day-key-note-content">
								<div className="day-row day-row-mobile">
										<div className="day-row-head">Keynote Address</div>
										<div className="day-row-text">
												<span>10.45 am – 11.30 am</span>
												<span> | </span>
												<span>Auditorium</span>
										</div>
								</div>
								<div className="day-row">
										<div className="day-row-main-head">Sustainable Education, Environment and Economy: Models for Action</div>
								</div>
								{/* <div className="day-row">
										<div className="speaker-image">
												<img src="src/assets/Speakers/sw.jpg" alt="Sonam Wangchuk" />
										</div>
										<div className="day-row-content">
												<div className="speaker-name">Sonam Wangchuk</div>
												<div className="speaker-role">Founder-Director, Students’ Educational and Cultural Movement of Ladakh, SECMOL</div>
										</div>
								</div> */}
						</div>
						<div className="day-panel-content spacer">
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Panel Discussion</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>11.30 am – 12.15 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Landscapes of Imagination: Creative and Practical Approaches to Arts Education</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker4} alt="Susanne Buch Nielsen " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Susanne Buch Nielsen </div>
														<div className="speaker-role">Associate Professor, VIA University College, Denmark</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker5} alt="Sophie Voegele " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Sophie Voegele </div>
														<div className="speaker-role">Researcher, Zurich University of the Arts, Switzerland</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker6} alt="Dr. Penny Hay " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Dr. Penny Hay </div>
														<div className="speaker-role">Research Fellow, Centre for Cultural and Creative Industries Senior Lecturer in Arts Education, School of Education Bath Spa University Director of Research, House of Imagination</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker7} alt="Dilbur Parakh" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Dilbur Parakh  </div>
														<div className="speaker-role">Trustee, Aseema Charitable Trust, Mumbai</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker8} alt="Lata Vaidhyanathan  " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Lata Vaidhyanathan  </div>
														<div className="speaker-role">Director, Made Easy School, New Delhi</div>
												</div>
										</div>
								</div>
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Panel Discussion</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>12.30 pm – 1.15 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Museum Education and School Education: Necessity of Convergence</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker9} alt="Roobina Karode" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Roobina Karode</div>
														<div className="speaker-role">Director, Kiran Nadar Museum of Art</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker10} alt="Tejshvi Jain " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Tejshvi Jain </div>
														<div className="speaker-role">Founder-Director, Rereeti/Revitalizing Museums</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker11} alt="Molly Kaushal " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Molly Kaushal </div>
														<div className="speaker-role">Professor and Head, Janapada Sampada, IGNCA</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker13} alt="Avni Sethi " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Avni Sethi  </div>
														<div className="speaker-role">Founder-Director, Conflictorium</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker14} alt="Poulomi Das" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Poulomi Das</div>
														<div className="speaker-role">Consultant, Museum and Heritage Spaces</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker12} alt="Sharmila Samant" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Sharmila Samant</div>
														<div className="speaker-role">Artist and Advisor, Art1st Foundation</div>
												</div>
										</div>
								</div>
						</div>
						<div className="day-panel-content">
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Paper Presentation</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>2.00 pm - 2.30 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Intersections of Museum Learning, Arts Thinking and Educational Psychology in the Last Two Decades</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker1} alt="Shailza Rai" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Shailza Rai  </div>
														<div className="speaker-role">Research Specialist, Flow India</div>
												</div>
										</div>
								</div>
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Paper Presentation</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>2.30 pm - 3.00 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Art, Ecology and Everyday Life: Creating Curriculums for Young Minds (EKW Project)</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker2} alt="Nobina Gupta  " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Nobina Gupta </div>
														<div className="speaker-role">Founder-Director, Disappearing Dialogues</div>
												</div>
										</div>
								</div>
						</div>
						<div className="day-panel-content spacer">
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Best Practices :: Case Studies</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>3.00 pm – 3.30 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">SlamOutLoud</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker3} alt="Jigyasa Labroo" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Jigyasa Labroo</div>
														<div className="speaker-role">CEO, Co-Founder Slam Out Loud</div>
												</div>
										</div>
								</div>
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Best Practices :: Case Studies</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>3.00 pm – 3.30 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Problem Se No Problem, TinkerLabs</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker15} alt="Sreya Majumder" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Sreya Majumder</div>
														<div className="speaker-role">Design Research & Visual Designer, TinkerLabs</div>
												</div>
										</div>	
										<div className="day-row space">
											<div className="speaker-image">
													<img src={Speaker16} alt="Avani Patel" />
											</div>
											<div className="day-row-content">
													<div className="speaker-name">Avani Patel</div>
													<div className="speaker-role">Design Strategist, TinkerLabs </div>
											</div>
										</div>
										
								</div>
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Thematic Workshops</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>3.45 pm – 6.00 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Creating Living Curriculums for Schools: Engaging with the Social</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														{/* <img src="src/assets/Speakers/tb.jpg" alt="Tultul Biswas " /> */}
												</div>
												<div className="day-row-content">
														<div className="speaker-name"></div>
														<div className="speaker-role"></div>
												</div>
										</div>										
								</div>
						</div>
						<div className="day-ilbas-content-ctr">
								<div className="day-ilbas-content">
										<div className="day-column">
												<div className="day-row">
													<div className="heading">Interactive Lab</div>
												</div>
												<div className="day-row">
														<div className="day-row-text">
															<span>10.00 am – 5.00 pm</span>
															<span> | </span>
															<span>Twin Art Gallery</span>
														</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-title">Build your Own Lab</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-speaker">ArtScience BLR, Srishti Institute of Art, Design and Technology | Bengaluru</div>
												</div>
										</div>
										<div className="day-column">
												<div className="day-row">
														<div className="heading">Interactive Lab</div>
												</div>
												<div className="day-row">
														<div className="day-row-text">
																<span>10.00 am – 1.00 pm, 2:00 pm - 5:00 pm</span>
																<span> | </span>
																<span>Twin Art Gallery</span>
														</div>
												</div>
												<div className="day-row">
														<div className="day-ilab-title">Culture and Museum Learning</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-speaker">FLOW India</div>
												</div>
										</div>
										<div className="day-column">
												<div className="day-row">
														<div className="heading">Interactive Lab</div>
												</div>
												<div className="day-row">
														<div className="day-row-text">
															<span>10.00 am – 1.00 pm, 2:00 pm - 5:00 pm</span>
															<span> | </span>
															<span>Twin Art Gallery</span>
														</div>
												</div>
												<div className="day-row">
														<div className="day-ilab-title">Creative Learning and Practice (CLaP)</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-speaker">Council for Arts and Social Practice (CASP)</div>
												</div>
										</div>
										<div className="day-column">
												<div className="day-row">
														<div className="heading">Interactive Lab</div>
												</div>
												<div className="day-row">
													<div className="day-row-text">
														<span>10.00 am – 1.00 pm, 2:00 pm - 5:00 pm</span>
														<span> | </span>
														<span>Twin Art Gallery</span>
													</div>
												</div>
												<div className="day-row">
														<div className="day-ilab-title">Partner a Master: Artist-Mentor Program</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-speaker">Lokesh Khodke, Artist, Illustrator & Co-founder BLUEJACKAL | Komal Pande, Assistant Curator (N&E) In-charge jewellry, National Museum, janpath</div>
												</div>
										</div>
										<div className="day-column">
												<div className="day-row">
														<div className="heading">Interactive Lab</div>
												</div>
												<div className="day-row">
													<div className="day-row-text">
														<span>10.00 am – 1.00 pm, 2:00 pm - 5:00 pm</span>
														<span> | </span>
														<span>Twin Art Gallery</span>
													</div>
												</div>
												<div className="day-row">
														<div className="day-ilab-title">UNBOX: An approach to Design Thinking</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-speaker">TinkerLabs</div>
												</div>
										</div>
								</div>
						</div>
						<div className="day-child-art-ctr">
								<div className="day-column">
										<div className="day-row">
												<div className="heading">Child Art Workshop</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>10.30 am - 12.30 pm</span>
														<span> | </span>
														<span>Amphitheatre</span>
												</div>
										</div>
										<div className="day-row-main-head">
												<div className="day-row-sub-head">Workshop 03</div>
										</div>
								</div>
								<div className="day-column">
									<div className="day-row">
										<div className="heading">Child Art Workshop</div>
									</div>
									<div className="day-row">
										<span>2.00 pm - 4.00 pm</span>
										<span> | </span>
										<span>Amphitheatre</span>
									</div>
									<div className="day-row-main-head">
										<div className="day-row-sub-head">Workshop 04</div>
									</div>
								</div>
						</div>
						
				</div>
		</div>
);

export default DayTwoContent;
