import React from 'react';

import Image1 from 'src/assets/Summit Modules/Keynote-Address.jpg';
import Image2 from 'src/assets/Summit Modules/Panel-Discussion.jpg';
import Image3 from 'src/assets/Summit Modules/Paper-Presentations.jpg';
import Image4 from 'src/assets/Summit Modules/Child-Art-Workshop.jpg';
import Image5 from 'src/assets/Summit Modules/Interactive-Labs.jpg';
import Image6 from 'src/assets/Summit Modules/Child-Art-Exhibition.jpg';


const modules = [
  { title: 'Keynote Addresses', image: Image1 },
  { title: 'Panel Discussion', image: Image2 },
  { title: 'Paper Presentations', image: Image3 },
  { title: 'Child Art Workshop', image: Image4 },
  { title: 'Interactive Labs', image: Image5 },
  { title: 'Child Art Museum', image: Image6 }
]

const modulesDom = modules.map((item, index) => (
  <div className="summit-modules-item">
    <div style={{ backgroundImage: `url(${item.image}` }} className="image-2"></div>
    <div className="summit-modules-content">
      <div className="summit-modules-title">{item.title}</div>
    </div>
  </div>
))
const SummitModules = () => (
  <div className="summit-modules-ctr">
    <div className="summit-modules-main-title">
		Summit Modules
    </div>
    <div className="summit-modules-items">
      {modulesDom}
    </div>
  </div>
)

export default SummitModules
