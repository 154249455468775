import React from 'react';
import { NavLink } from 'react-router-dom';

import './Header.less';
import ImgArtEdLogo from 'assets/Art Education Summit Logo_Final-13@2x.png';
import Intro1 from 'assets/IGNCA LOGO New.png';
import Intro2 from 'assets/Art1st Logo-01.png';
import Intro3 from 'assets/cbse-logo.png';

const Header = () => (
    <header className="header-ctr">
        <div className="header-content">
            <div className="header-logo">
                <a href="/">
                    <img src={ImgArtEdLogo} alt="Art Education Summit" />
                </a>
                {/* <hr />
                <div className="header-logo-content">
					<div className="logo-1">
							<img src={Intro1} alt="Art Education Summit" />
					</div>
					<div className="logo-2">
							<img src={Intro2} alt="Art Education Summit" />
					</div>
					<div className="logo-3">
							<img src={Intro3} alt="Art Education Summit" />
					</div>
				</div> */}
            </div>
            <div className="header-links">
								<ul>
										<li>
												<NavLink to="/schedule" className="normal" activeClassName="active" exact>Schedule</NavLink>
										</li>
										<li>
												<NavLink to="/speakers" className="normal" activeClassName="active" exact>Speakers</NavLink>
										</li>
										<li>
												<NavLink to="/ilabs" className="normal" activeClassName="active" exact>Interactive Labs</NavLink>
										</li>
										<li>
												<NavLink to="/childartmuseum" className="normal" activeClassName="active" exact>Child Art Museum</NavLink>
										</li>
										<li>
												<NavLink to="/about" className="normal" activeClassName="active" exact>About</NavLink>
										</li>
										<li>
												<NavLink to="/help" className="normal" activeClassName="active" exact>FAQs</NavLink>
										</li>
										{/* <li className="register-btn">
													<a href="https://forms.gle/Xvoc3GmEkxkA5Cis6" target="_blank">REGISTER</a>
										</li> */}
								</ul>
            </div>
        </div>
    </header>
);

export default Header;
