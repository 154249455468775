import React, { Component } from 'react';

import DayOneContent from './DayOneContent';
import DayTwoContent from './DayTwoContent';
import DayThreeContent from './DayThreeContent';

class IntroductorySchedule extends Component {
	constructor(props) {
			super(props);
			this.state = {
					day1: true,
					day2: false,
					day3: false,
			};
	}

	handleclick(day) {
			console.log(day);

			switch(day) {
					case 'day1':
							this.setState({
									day1: true,
									day2: false,
									day3: false
							});
							break;
					case 'day2':
							this.setState({
									day1: false,
									day2: true,
									day3: false
							});
							break;
					case 'day3':
							this.setState({
									day1: false,
									day2: false,
									day3: true
							});
							break;
					default:
							this.setState({
									day1: true,
									day2: false,
									day3: false
							});
			}
	}

	render() {
			const { day1, day2, day3 } = this.state;
			return (
					<div className="introductory-schedule-ctr">
							<div className="introductory-schedule-content">
									<div className="introductory-schedule-left">
											<div className="day-list-ctr">
													<div className="day-list-content">
															<div className="day-list-head">Search by day</div>
															<div className={day1 ? 'day-list active-state' : 'day-list'} onClick={() => {this.handleclick('day1')}}>
																	Day 1 | Dec 18
															</div>
															{
																	day1 && ( <div className="day-list-panel">Arts and Cultural Policy</div>)
															}
															<div className={day2 ? 'day-list active-state' : 'day-list'} onClick={() => {this.handleclick('day2')}}>
																	Day 2 | Dec 19
															</div>
															{
																	day2 && (<div className="day-list-panel">Teaching and Learning Practices</div>)
															}
															<div className={day3 ? 'day-list active-state' : 'day-list'} onClick={() => {this.handleclick('day3')}}>
																	Day 3 | Dec 20
															</div>
															{
																	day3 && (<div className="day-list-panel">Transforming Curriculums</div>)
															}
													</div>
											</div>
									</div>
									<div className="introductory-schedule-right">
											{
													day1 && ( <DayOneContent />)
											}
											{
													day2 && ( <DayTwoContent />)
											}
											{
													day3 && ( <DayThreeContent />)
											}
									</div>
							</div>
					</div>
				);
		}
}

export default IntroductorySchedule;
