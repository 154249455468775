import React from 'react';
import { Link } from "react-router-dom";

import './Principals.less';
import PrincipalsIcon from 'assets/Group 90@2x.png';

const Principals = () => (
    <div className="principal-ctr">
        <div className="principal-content">
            <div className="principal-content-left">
                <h3 className="principal-heading">For Principals</h3>
                <div className="principal-text">
                    Enrich your school's environment, enhance classroom experiences and redefine
										your pedagogical philosophy.
                </div>
                <div className="principal-redirect">
                    <Link to="/speakers">Invited Speakers</Link>
                </div>
            </div>
            <div className="principal-content-right">
                <img src={PrincipalsIcon} alt="For Principals" />
            </div>
        </div>
    </div>
);

export default Principals;
