import React from 'react';

import Image1 from 'src/assets/Interactive Labs/Build you own lab.jpg';
import Image2 from 'src/assets/Interactive Labs/Creative Practice & Learning.jpg';
import Image3 from 'src/assets/Interactive Labs/Culture Connectors.jpg';
import Image4 from 'src/assets/Interactive Labs/Cuture and Museum Learning.jpg';
import Image5 from 'src/assets/Interactive Labs/Partner-a-master.jpg';
import Image6 from 'src/assets/Interactive Labs/Interactive-Lab_06_Unbox.png';

const OwnLabs = () => (
    <div className="own-labs-ctr">
    		<div className="own-labs-content">
    				<div className="own-labs-items">
    						<div className="own-labs-item-left">
    								<div className="own-labs-item-title">
    										Build your Own Lab
    								</div>
    								<img src={Image1} alt="Banner Image"/>
    						</div>
    						<div className="own-labs-item-right">
    								<div className="bold-text-1">Conducted by ArtScience BLR</div>
    								<p>
    										This lab will provide hands on training for teachers in cross-disciplinary collaborations between science-technology-arts. It will help teachers understand how scientific concepts, technological developments and creative interventions could all combine together to provide innovative and creative solutions for the multidisciplinary tasks of our times. Participants must bring their own laptop!
    								</p>
    								<div className="bold-text-1">Module 1 : Build your own Pollution Sensor</div>
    								<div className="bold-text-2">Dec 18 | Day 1 | Full Day Session</div>
    								<p>
    										Considering the current crises in New Delhi, It would be apt that we learn how build a people’s pollution detector. These sensors can be networked to share data. At the end of the workshop, the participants should be able to understand basic microcontroller programming, how to use sensors and use this as a starting point to build other devices that might interest the participants or their local communities.
    								</p>
    								<div className="bold-text-1">Module 2 : Build your own Microscope</div>
    								<div className="bold-text-2">Dec 19 | Day 2 | Full Day Session</div>
    								<p>
    									Build a high end hackteria microscope and capture and analyze your natural and man-made environment. We will also learn how to collectively look for interesting samples in our local environments and see how to use the microscope as a pedagogical tool to further study our environment.
    								</p>
    								<div className="bold-text-1">Module 3 : Build your own Pollution Sensor</div>
    								<div className="bold-text-2">Dec 20 | Day 3 | Full Day Session</div>
    								<p>
    									Considering the current crises in New Delhi, It would be apt that we learn how build a people’s pollution detector. These sensors can be networked to share data. At the end of the workshop, the participants should be able to understand basic microcontroller programming, how to use sensors and use this as a starting point to build other devices that might interest the participants or their local communities.
    								</p>
    								<div className="bold-text-1">About the Facilitators</div>
    								<div className="bold-text">
    										(Art)ScienceBLR is a public laboratory at the Srishti Institute of Art, Design and Technology, Bengaluru, for artists and designers to engage with scientific practices. We look at the artistic, social and political implications of technologies from computing to biotechnologies.
    								</div>
    								<br />
    								<a href="https://artscienceblr.org/" className="bold-text-2">
    										Read More
    								</a>
								</div>
    				</div>
    				<div className="own-labs-items">
    						<div className="own-labs-item-left">
    								<div className="own-labs-item-title">
												Culture Connectors
										</div>
										<img src={Image3} alt="Banner Image"/>
								</div>
								<div className="own-labs-item-right">
										<div className="bold-text-1">Conducted by FLOW, India</div>
										<br />
										<div className="bold-text-1">Module: Experiential Lab</div>
										<div className="bold-text-2">All 3 days | Free walk-ins</div>
										<p>
												How can one engage communities, with a particular focus on young people, to contemplate on their relation with cultural heritage in order to foster reflection on a shared past, present challenges and future co-existence? The project aims to leverage the power of virtual reality and digital storytelling to make heritage accessible and engaging for young learners across the country who may not have the opportunity to access it otherwise. In this lab, teachers will engage with the exciting modules to redefine our engagement with heritage by allowing learners to make personal connections, gain a bigger picture of the past and the present to understand continuity and change.
										</p>
										<div className="bold-text-1">About the Facilitators</div>
										<div className="bold-text">
												Flow India is an education and culture organization with a human-centered design focus. It works with a range of Engaged Cultural, experiential and enquiry-based methodologies, supporting its stakeholders in making the real world, accessible, relevant and sustainable as a resource for learning.
										</div>
										<br />
										<a href="http://flowindia.com/" className="bold-text-2">
												Read More
										</a>
								</div>
						</div>
						<div className="own-labs-items">
								<div className="own-labs-item-left">
										<div className="own-labs-item-title">
												Culture and Museum Learning
										</div>
										<img src={Image4} alt="Banner Image"/>
								</div>
								<div className="own-labs-item-right">
										<div className="bold-text-1">Conducted by FLOW, India</div>
										<div className="bold-text-1">Module: Museum of Idea and Objects</div>
										<div className="bold-text-2">All 3 days | Morning Session | Afternoon Session</div>
										<p>
											The Museum of Ideas and Objects explores the skills of cognition, emotional acquity and creative making with tangible objects and intangible ideas. Come explore your skills at playing with them and create a little museum of your own in the process. Separate strands for young adults and educators.
										</p>
										<div className="bold-text-1">About the Facilitators</div>
										<div className="bold-text">
										Flow India is an education and culture organization with a human-centered design focus. It works with a range of Engaged Cultural, experiential and enquiry-based methodologies, supporting its stakeholders in making the real world, accessible, relevant and sustainable as a resource for learning. 
										</div>
										<br />
										<a href="http://flowindia.com/" className="bold-text-2">
												Read More
										</a>
								</div>
						</div>
						<div className="own-labs-items">
								<div className="own-labs-item-left">
										<div className="own-labs-item-title">
												Creative Learning and Practice (CLaP)
										</div>
										<img src={Image2} alt="Banner Image"/>
								</div>
								<div className="own-labs-item-right">
										<div className="bold-text-1">Conducted by Council for Arts and Social Practice</div>
										<p>
												The lab will introduce teachers to creative learning modules that involve artful communication strategies, engaging with the architectural spaces in schools, and enhancing cognitive and participatory skills in students. These replicable modules can be implemented by teachers in their own contexts which involve integrating maths, space and time, migration and ecology, carbon footprint calculator, waste segregation and value-led learning through the mediums of art, design, and site-specific architecture.
										</p>
										<div className="bold-text-1">Module 1 : Ecological Sustainability & Climate Change</div>
										<div className="bold-text-2">Dec 18 | Day 1 | Morning Session</div>
										<p>
										The theme entails the use of art for learning about environment issues and sustainability by virtue of art products and processes, to
delve into various facets of the ecological crisis situation. Waste management, carbon footprint and impact of climate change on the
natural habitat of birds and animals are some of the key aspects that are addressed through children engagement-oriented processes
and art-in-space as learning tools – to advocate sustainable ways for ecological preservation and response to depleting resources,
pollution and climate change..
										</p>
										<div className="bold-text-1">Module 2 : Questioning Socio-cultural Perceptions</div>
										<div className="bold-text-2">Dec 18 | Day 1 | Afternoon Session</div>
										<p>
										Children can be exposed to the questioning stereotypes, social perceptions and cultural norms by engaging their minds through art.
This theme particularly addresses gender stereotypes and illustrates how art processes and engagement of children to co-create an art
installation in their learning environment, can help their ‘flip’ gender stereotypes – to stir a conversation on gender equality and
equal access to opportunities, beyond the barriers of stereotypes.
										</p>
										<div className="bold-text-1">Module 3 : Subject-oriented Lessons</div>
										<div className="bold-text-2">Dec 19 | Day 2 | Morning Session</div>
										<p>
										This module explores how subjects like mathematics, science and geography can be learned by the help of spatial art products as
learning tools for hands-on engagement and immersive participation. Concepts like algebra can be learned by using life-size wooden
tiles to solve puzzles, simple mathematics can be grasped with a big abacus, a 3-dimensional puzzle cube can help learn about
countries and continents and a life-size sun dial can help children explore the relationship of the sun with time.
										</p>
										<div className="bold-text-1">Module 4 : Emo-Totems</div>
										<div className="bold-text-2">Dec 19 | Day 2 | Afternoon Session</div>
										<p>
											Emo-Totems is understanding emotions using dancing totems. It is spatial and interactive in nature, which creates a space for the
                      children to realise and channelize their emotions through play. The dancing totems are used to address affective pedagogy in schools.
                      The learning lies in the fact that no matter what the intensity the totem-object is subjected to, the design enables it to regain back its
                      original form.
										</p>
										<div className="bold-text-1">Module 5 :  Mapping everyday environments</div>
										<div className="bold-text-2">Dec 20 | Day 3 | Morning Session</div>
										<p>
										This theme explores how art installation in space and learning tools can help children map their surroundings as well as build with
their own hands to learn about form, maps and shelters. A neighbourhood map is understood through the game of hopscotch, for
instance, and a kit of metal members along with nuts and bolts allows for an engagement tool to construct their own builtenvironment.
										</p>
										<div className="bold-text-1">Module 6 :  Future paths and choosing a “career”</div>
										<div className="bold-text-2">Dec 20 | Day 3 | Afternoon Session</div>
										<p>The daunting aspect of future pathways and professional pursuits is explored in their theme, by indulging art-in-space. Concepts of
skills, higher education, passion, vocation and profession can be explored and the minds of children can be exposed to the world of
possibilities beyond barriers by using art processes and products.
										</p>
										<div className="bold-text-1">About the Facilitators</div>
										<div className="bold-text">
												Established in 2013, Council for Arts and Social Practice (CASP) is a platform for transdisciplinary artistic research and practice to facilitate critical dialogues on cultural sustainability.
										</div>
										<br />
										<a href="https://www.facebook.com/Council-for-Arts-and-Social-Practice-518119484950980/?ref=search&__tn__=%2Cd%2CP-R&eid=ARCGieT9WxgkZ51QA-sp2eafbYMFlVbzN0mCz54nIhlxgPxlbGmadqtMYY6284-RpQJ6Y7EZWLIz54Tm" className="bold-text-2">
												Read More
										</a>
								</div>
						</div>
						<div className="own-labs-items">
								<div className="own-labs-item-left">
										<div className="own-labs-item-title">
												Partner a Master: Artist-Mentor Program
										</div>
										<img src={Image5} alt="Banner Image"/>
								</div>
								<div className="own-labs-item-right">

										<div className="bold-text-1">Conducted by Contemporary Artists</div>
										<br />
										<div className="bold-text-1">Art1st Partner-a-Master</div>
										<p>
												Employing art as a pedagogical tool, this workshop emphasizes on visual literacy, collective learning, and the role of the arts in cognitive and creative competencies. In this lab, contemporary national and international artists will directly interact with teachers through hands-on exercises, analysis and discussions based on their creative processes and methods. The teachers will be encouraged to implement resulting ideas with their students.
										</p>
										<div className="bold-text-1">Module 1 : Future &gt;&gt;&lt;&lt; Past: Creativity in the everyday with Sonal Jain</div>
										<div className="bold-text-2">Dec 18 | Day 1 | Full Day Session</div>
										<p>
										Explores the intersection between the self and the world around; the future and the past; the left and the right hemispheres of the brain; the creative and the rational mind. Through process work and engaged learning the participants discover their own place in the present and where each one is coming from leading to an understanding where one is going.
										</p>
										<div className="bold-text-1">Module 2: Storytelling with Malvika Joshi</div>
										<div className="bold-text-2">Dec 18 | Day 1 | Morning Session</div>
										<p>Malavika Joshi brings her years of experience as a teacher and culture and arts facilitator to the session. Participants take on familiar stories such as ‘Panchatantras’ to invite students into the world of storytelling. Children have an inherent curiosity and a knack for storytelling. The session will introduce storytelling as a classroom tool and how to use different ways of telling a story, effectively. </p>
										<div className="bold-text-1">Module 3 : Lokesh Khodke</div>
										<div className="bold-text-2">Dec 19 | Day 2 | Morning Session</div>
										<p>This workshop is for art teachers in understanding and developing interesting insights into creating visual narratives in the medium of comics. Comics was initially understood as a simple reading material for children's readership. But in time it has changed a lot. Now it has taken a new avatar and it is created for much larger age group readership. It is also known as 'graphic novel', or 'graphic narrative' now. During this workshop we all will go through various types of comics which are created for many different age groups. And then the participants will do some short exercises and make their own short comics on the day. </p>
										<div className="bold-text-1">Module 4: Via the Museum 1: Concept and Collection: Art Education in the Museums with Komal Pande</div>
										<div className="bold-text-2">Dec 19 | Day 2 | Afternoon Session</div>
										<p>Explore the potential of the museums as fundamental institutes of learning with Komal Pande. Participants consider museum collections, on display and in reserve as the session enhances the understanding of various concepts of art and how it changes when art reaches the museum. </p>
										<div className="bold-text-1">Module 5: Via the Museum 2: Context and Content: Possibilities of Curation with Komal Pande</div>
										<div className="bold-text-2">Dec 20 | Day 3 | Morning Session</div>
										<p>Join artist Komal Pande in a session to explore ways in which Curation can challenge the way in which we look at art. It will emphasize on multiple contexts in which content viz. text and art can be curated to give the desired impetus to the theme of the presentation.</p>
										<div className="bold-text-1">Module 6 : Elke Zobl</div>
										<div className="bold-text-2">Dec 20 | Day 3 | Afternoon Session</div>
										<p>In this workshop Elke Zobl will introduce the participants to a range of zines (mainly with feminist and anti-racist perspectives) and the participants will make zines hands-on. Elke will also show the toolbox and the materials she has developed with a team in the project "Making Art, Making Media, Making Change!" (www.makingart.at) to be used in working with young people in schools or youth centres. The workshop is set up as a digital webinar whereby a group of people in Salzburg will also attend the workshop with Elke.</p>
										<div className="bold-text-1">About the Facilitators</div>
										<div className="bold-text">
												Art1st Foundation, since its establishment in 2009, has consistently addressed issues in our art education system through curricular and teacher training programmes, publications, films, workshops, research, exhibitions and seminars. It has nurtured learning programmes with schools, children, art teachers, educators, and NGOs across India.
										</div>
										<br />
										<a href="http://art1st.co.in/art-studio/programmes/partner-a-master-13-18-years/" className="bold-text-2">
												Read More
										</a>
								</div>
						</div>
						<div className="own-labs-items">
								<div className="own-labs-item-left">
										<div className="own-labs-item-title">
												UNBOX: An approach to Design Thinking
										</div>
										<img src={Image6} alt="Banner Image"/>
								</div>
								<div className="own-labs-item-right">
										<div className="bold-text-1">Conducted by Quicksand, TinkerLabs and D-Van</div>
										<p>
										This Unbox Lab invites educators to apply Design Thinking to their classrooms. The sessions are conducted by experts who walk the talk and live the very philosophy of Design Thinking. The Labs will take you through Empathy as a foundation, building Creative Confidence and Changing Perceptions to inspire innovation.
										</p>
										<br />
										<div className="bold-text-1">Module 1: Acting on Empathy: Design Thinking for Educators by Quicksand</div>
										<div className="bold-text-2">Dec 18 | Day 1 | Morning and Afternoon Session</div>
										<p>Quicksand will introduce the process of design thinking through a simple hands-on workshop where teachers will be presented with a challenge, and through a series of guided steps, seek to understand, brainstorm, ideate and explore potential, while focusing on Empathy as a foundation. The workshop will give educators a template to pick interesting and engaging challenges and run these sessions with children within the classroom setting.<br /><a className="bold-text-2" href="http://quicksand.co.in/about" target="_blank">Read more</a></p>

										<div className="bold-text-1">Module 2: Changing perceptions: A Teacher’s guide to Design thinking with TinkerLabs</div>
										<div className="bold-text-2">Dec 19 | Day 2 | Morning and Afternoon Sessions</div>
										<p>Explore the magic of Empathy, Collaboration and Smart Experimentation with Tinkerlabs, in curated workshops aimed at sensitizing teachers on how design thinking can impact innovation in the classrooms.Tinker Labs introduces a new age of change-makers  to channel their energies to making a better world, while teaching them how Design is a process of problem-solving until you reach the solution that works best in a given context. <br /><a className="bold-text-2" href="http://www.tinkerlabs.in/about/" target="_blank">Read more</a></p>

										<div className="bold-text-1">Module 3: Creative Confidence through Design Thinking with D-Van</div>
										<div className="bold-text-2">Dec 20 | Day 3 | Morning and Afternoon Sessions</div>
										<p>Design Thinkers focus on people’s latent needs and inspire confidence to solve them. Patterns developed through repetitive action assist us in familiar situations, but can prevent us from developing new ways of seeing, understanding and solving problems. In this session, D-Van will help participants flex their creative muscles and inculcate core principles of innovation and open minded problem solving in classrooms. Work as a team to build Creative Confidence, critically question the underlying assumptions behind a problem and build comfort with experimentation. <br /><a className="bold-text-2" href="https://www.thedvan.com/" target="_blank">Read more</a></p>
								</div>
						</div>
    		</div>
		</div>
);

export default OwnLabs;
