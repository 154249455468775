import React from 'react';

import AboutImage from 'assets/Rectangle 64@2x.png';

const AboutBanner = () => (
    <div className="about-banner-ctr">
    	<img src={AboutImage} alt="Banner Image"/>
				<div className="about-banner-content banner-header">
						About the Summit
				</div>
		</div>
);

export default AboutBanner;
