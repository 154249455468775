import React from 'react';

import FAQImage from 'assets/Rectangle 64@2x.png';

const FAQBanner = () => (
    <div className="faq-banner-ctr">
				<img src={FAQImage} alt="Banner Image"/>
						<div className="faq-banner-content">
								Frequently Asked Questions
						</div>
		</div>
);

export default FAQBanner;
