import React from 'react';
import Speaker1 from 'src/assets/Speakers/portrait-of-Susanta-Mandal.jpg';
import Speaker2 from 'src/assets/Speakers/lk.jpg';
import Speaker3 from 'src/assets/Speakers/_Dr.-Tanu-Shree-Singh.jpg';
import Speaker4 from 'src/assets/Speakers/Charlotte-E.-Leech.jpg';
import Speaker5 from 'src/assets/Speakers/PennyHay.jpg';
import Speaker6 from 'src/assets/Speakers/PoojaThakur.jpeg';
import Speaker7 from 'src/assets/Speakers/gaur.jpg';
import Speaker8 from 'src/assets/Speakers/Dr Rizio B Yohannan.jpg';
import Speaker9 from 'src/assets/Speakers/Arundhati-Mitter.jpg';
import Speaker10 from 'src/assets/Speakers/Amritha-S-Radhakrishnan.jpg';
import Speaker11 from 'src/assets/Speakers/Geeti-Karkamar.jpg';
import Speaker12 from 'src/assets/Speakers/Ritu-Khoda_founder.jpg';
import Speaker13 from 'src/assets/Speakers/Dr-Sangeeta-Gole.jpg';
import Speaker14 from 'src/assets/Speakers/headshotjigyasapassport.jpg';

const DayThreeContent = () => (
		<div className="day-content-ctr">
				<div className="day-content">
						<div className="day-intro-content day-key-note-content">
								<div className="day-row day-row-mobile">
										<div className="day-row-head">Keynote Address</div>
										<div className="day-row-text">
												<span>10.45 am – 11.30 am</span>
												<span> | </span>
												<span>Auditorium</span>
										</div>
								</div>
								<div className="day-row">
										<div className="day-row-main-head">Leading Education Curriculums</div>
								</div>
								{/* <div className="day-row">
										<div className="speaker-image">
												<img src="src/assets/Speakers/Sachchidanand Joshi.jpg" alt="Sachchidanand Joshi" />
										</div>
										<div className="day-row-content">
												<div className="speaker-name">Sachchidanand Joshi</div>
												<div className="speaker-role">Member Secretary, IGNCA</div>
										</div>
								</div> */}
						</div>
						<div className="day-panel-content spacer">
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Panel Discussion</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>11.30 am – 12.15 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Affective Pedagogy and Emotional Nurturing through the Arts: A Child-Centric Approach</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker1} alt="Sushanta Mandal " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Sushanta Mandal </div>
														<div className="speaker-role">Artist</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker2} alt="Lokesh Khodke" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Lokesh Khodke</div>
														<div className="speaker-role">Artist, Illustrator & Co-founder BLUEJACKAL</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker3} alt="Dr. Tanu Shree Singh" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Dr. Tanu Shree Singh </div>
														<div className="speaker-role">Author and Positive Psychologist</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker4} alt="Charlotte Leech  " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Charlotte Leech  </div>
														<div className="speaker-role">Co-Founder, Loka Foundation</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker5} alt="Dr. Penny Hay  " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Dr. Penny Hay  </div>
														<div className="speaker-role">Research Fellow, Centre for Cultural and Creative Industries Senior Lecturer in Arts Education, School of Education Bath Spa University Director of Research, House of Imagination</div>
												</div>
										</div>
								</div>
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Panel Discussion</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>12.30 pm – 1.15 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Collaborative Models and Action Research in Schools</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker13} alt="Sangeeta Gole" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Sangeeta Gole</div>
														<div className="speaker-role">Director, PEARLS & Art1st Advisor</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker6} alt="Pooja Thakur" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Pooja Thakur</div>
														<div className="speaker-role">Principal, The Shri Ram School</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker7} alt="Prof. Ramesh C. Gaur " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Prof. Ramesh C. Gaur</div>
														<div className="speaker-role">Dean and Director (Lib. &amp; Inf.), Indira Gandhi National Centre for the Arts</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker8} alt="Dr. Rizio B. Yohannan  " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Dr. Rizio B. Yohannan  </div>
														<div className="speaker-role">CEO, Marg Foundation</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker9} alt="Arundhati Mitter " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Arundhati Mitter</div>
														<div className="speaker-role">Director, Flow India</div>
												</div>
										</div>
								</div>
						</div>
						<div className="day-panel-content">
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Paper Presentation</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>2.00 pm - 2.30 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Art Integrated Learning: Methods towards finding Patronage for Arts through Education</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker10} alt="Amritha S. Radhakrishnan " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Amritha S. Radhakrishnan </div>
														<div className="speaker-role">PhD Scholar, School of Arts and Aesthetics, Jawaharlal Nehru University</div>
												</div>
										</div>
								</div>
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Paper Presentation</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>2.30 pm - 3.00 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Relevance of Collaborative Art Projects in the Arts Education System</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker11} alt="Geeti Karmakar   " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Geeti Karmakar  </div>
														<div className="speaker-role">PhD Scholar, Centre for Folk Culture Studies, University of Hyderabad</div>
												</div>
										</div>
								</div>
						</div>
						<div className="day-panel-content spacer">
								{/* <div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Best Practices :: Case Studies</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>3.00 pm – 3.30 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">SlamOutLoud, Delhi</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker14} alt="Jigyasa Labroo " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Jigyasa Labroo </div>
														<div className="speaker-role">CEO, Co-Founder Slam out Loud </div>
												</div>
										</div>
								</div> */}
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Best Practices :: Case Studies</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>3.00 pm – 3.30 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Approaching Art Education through Engagement and Interaction: The Art1st Way</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker12} alt="Ritu Khoda" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Ritu Khoda</div>
														<div className="speaker-role">Founder-Director, Art1st</div>
												</div>
										</div>
								</div>
						</div>
						<div className="day-ilbas-content-ctr">
								<div className="day-ilbas-content">
										<div className="day-column">
												<div className="day-row">
													<div className="heading">Interactive Lab</div>
												</div>
												<div className="day-row">
														<div className="day-row-text">
															<span>10.00 am – 5.00 pm</span>
															<span> | </span>
															<span>Twin Art Gallery</span>
														</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-title">Build your Own Lab</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-speaker">ArtScience BLR, Srishti Institute of Art, Design and Technology</div>
												</div>
										</div>
										<div className="day-column">
												<div className="day-row">
														<div className="heading">Interactive Lab</div>
												</div>
												<div className="day-row">
														<div className="day-row-text">
																<span>10.00 am – 1.00 pm, 2:00 pm - 5:00 pm</span>
																<span> | </span>
																<span>Twin Art Gallery</span>
														</div>
												</div>
												<div className="day-row">
														<div className="day-ilab-title">Culture and Museum Learning</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-speaker">FLOW India</div>
												</div>
										</div>
										<div className="day-column">
												<div className="day-row">
														<div className="heading">Interactive Lab</div>
												</div>
												<div className="day-row">
														<div className="day-row-text">
															<span>10.00 am – 1.00 pm, 2:00 pm - 5:00 pm</span>
															<span> | </span>
															<span>Twin Art Gallery</span>
														</div>
												</div>
												<div className="day-row">
														<div className="day-ilab-title">Creative Learning and Practice (CLaP)</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-speaker">Council for Arts and Social Practice</div>
												</div>
										</div>
										<div className="day-column">
												<div className="day-row">
														<div className="heading">Interactive Lab</div>
												</div>
												<div className="day-row">
													<div className="day-row-text">
														<span>10.00 am – 1.00 pm, 2:00 pm - 5:00 pm</span>
														<span> | </span>
														<span>Twin Art Gallery</span>
													</div>
												</div>
												<div className="day-row">
														<div className="day-ilab-title">Partner a Master: Artist-Mentor Program</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-speaker">Komal Pande, Assistant Curator (N&E) In-charge jewellry, National Museum, Janpath | Elke Zobl, Associate Professor, Department of Communication and Program Head, Contemporary Arts & Cultural Production, University Mozarteum Salzburg, Austria</div>
												</div>
										</div>
										<div className="day-column">
												<div className="day-row">
														<div className="heading">Interactive Lab</div>
												</div>
												<div className="day-row">
													<div className="day-row-text">
														<span>10.00 am – 1.00 pm, 2:00 pm - 5:00 pm</span>
														<span> | </span>
														<span>Twin Art Gallery</span>
													</div>
												</div>
												<div className="day-row">
														<div className="day-ilab-title">UNBOX: An approach to Design Thinking</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-speaker">d-Van</div>
												</div>
										</div>
								</div>
						</div>
						<div className="day-child-art-ctr">
								<div className="day-column">
										<div className="day-row">
												<div className="heading">Child Art Workshop</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>10.30 am - 12.30 pm</span>
														<span> | </span>
														<span>Amphitheatre</span>
												</div>
										</div>
										<div className="day-row-main-head">
												<div className="day-row-sub-head">Workshop 05</div>
										</div>
								</div>
								<div className="day-column">
										<div className="day-row">
												<div className="heading">Child Art Workshop</div>
										</div>
										<div className="day-row">
											<div className="day-row-text">
												<span>2.00 pm – 4:00 pm</span>
												<span> | </span>
												<span>Amphitheatre</span>
											</div>
										</div>
										<div className="day-row-main-head">
												<div className="day-row-sub-head">Workshop 06</div>
										</div>
								</div>
						</div>
						
				</div>
		</div>
);

export default DayThreeContent;
