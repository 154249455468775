import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DayPlanner from './DayPlanner/DayPlanner';
import DayPlannerBanner from './DayPlannerBanner';
import './RegistrationForm.less';

class RegistrationUpdateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formSubmitted: false,
        };
    }

    render() {
        const { formSubmitted } = this.state;
        return (
            <div className="registration-form-ctr">
                <DayPlannerBanner />
                
                <DayPlanner />
            </div>
        );
    }
}


export default RegistrationUpdateForm;
