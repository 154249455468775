import React from 'react';
import { Link } from "react-router-dom";

import './PolicyMakers.less';
import PolicyMakersIcon from 'assets/Group 135@2x.png';

const PolicyMakers = () => (
    <div className="policy-maker-ctr">
        <div className="policy-maker-content">
            <div className="policy-maker-content-left">
                <h3 className="policy-maker-heading">For Policy Makers</h3>
                <div className="policy-maker-text">
                    Explore innovative solutions to transform art education in India.
                </div>
                <div className="policy-maker-redirect">
                    <Link to="/schedule">Summit Schedule</Link>
                </div>
            </div>
            <div className="policy-maker-content-right">
                <img alt="For Policy Makers" src={PolicyMakersIcon} />
            </div>
        </div>
    </div>
);

export default PolicyMakers;
