import React from 'react';

import './Payment.less';

const Payment = () => {
    return (
        <div className="payment-ctr">
        		<hr />
            <div className="payment-heading">Payment</div>
            <div className="payment-sub-heading">
                <div>Registration Fees for the 3-day Summit is Rs. 3,000/- </div>
                <div>You can complete the payment via bank transfer or cheque.</div>
            </div>
            <div className="payment-text-1">
                <div className="payment-bold-text">
                    By NEFT Bank transfer:
                </div>
                <div className="payment-text">
                    Account name: Artfirst Foundation
                </div>
                <div className="payment-text">
                    Bank Name : Kotak Mahindra Bank-9404
                </div>
                <div className="payment-text">
                    A/c No. : 2512479404
                </div>
                <div className="payment-text">
                    Branch & IFS Code: Mumbai-Kalina & KKBK0000631
                </div>
            </div>
            <div className="payment-text-1">
                <div className="payment-bold-text">
                    By cheque:
                </div>
                <div className="payment-text">
                    Name on cheque : Artfirst Foundation
                </div>
                <div className="payment-text">
                    Courier the cheque to:
                </div>
                <div className="payment-text">
                    Priyam Mehta, Artfirst foundation,
                </div>
                <div className="payment-text">
                    B 701, Varsha Olive, Shraddhanand road,
                </div>
                <div className="payment-text">
                    Lane opposite Akruti Erica building,
                </div>
                <div className="payment-text">
                    Vile Parle(east) Mumbai: 400057.
                </div>
                <div className="payment-text">
                    Contact No.: 9987962986
                </div>
            </div>
            <div className="payment-text-1">
                Once your payment is processed by Art1st, your registration will be complete. You will be notified of the same.
            </div>
            <div className="payment-text-1">
            		*For NEFT Bank Transfer: Once your payment is processed, please email a screenshot of your transaction reference number to priyam@art1st.co.in
        		</div>
        </div>
    );
};

export default Payment;
