import React from 'react';

import Banner from '../Banner/Banner';
import Contact from '../Contact/Contact';
import About from '../About/About';
import Footer from '../Footer/Footer';
import FooterMobile from '../Footer/FooterMobile';
import Organizers from '../Organizers/Organizers';
import OrganisersPage from '../Pages/OrganisersPage';

import './Pages.less';

const AboutPage = () => (
    <div className="main-ctr">
        <About />
        <hr />
        <OrganisersPage />
        <div className="desktop-layout contact-organizers-ctr">
						<div className="contact-component">
								<Contact />
						</div>
						<div className="show-in-desktop">
								<div className="organizers-component">
										<Organizers />
										<Footer />
								</div>
						</div>
						<div className="show-in-mobile">
								<FooterMobile />
						</div>
				</div>
    </div>
);

export default AboutPage;
