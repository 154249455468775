import React from 'react';
import { Link } from "react-router-dom";

import './Parents.less';
import ParentsIcon from 'assets/Group 104@2x.png';

const Parents = () => (
    <div className="parents-ctr">
        <div className="parents-content">
            <div className="parents-content-left">
                <h3 className="parents-heading">For Parents</h3>
                <div className="parents-text">
                    Witness the potential of art in education, creative development and as a future career.
                </div>
                <div className="parents-redirect">
                    <Link to="/childartmuseum">Child Art Museum</Link>
                </div>
            </div>
            <div className="parents-content-right">
                <img src={ParentsIcon} alt="For Parents" />
            </div>
        </div>
    </div>
);

export default Parents;
