import React from 'react';

import SummitScheduleBanner from './SummitScheduleBanner';
import SummitModules from './SummitModules';
import IntroductorySchedule from './IntroductorySchedule';
import './SummitSchedule.less';

const SummitSchedule = () => (
    <div className="summit-schedule-ctr">
				<SummitScheduleBanner />
				<SummitModules />
				<IntroductorySchedule />
    </div>
);

export default SummitSchedule;
