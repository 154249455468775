import React from 'react';

import SpeakersBannerImage from 'assets/Rectangle 64@2x.png';

const SpeakersBanner = () => (
    <div className="speakers-banner-ctr">
    	<img src={SpeakersBannerImage} alt="speakers Image"/>
				<div className="speakers-banner-content">
						<div className="speakers-banner-title banner-header">Summit Speakers</div>
						<div className="speakers-banner-text banner-text">
								Engage with keynote speakers, panelists, researchers, and experts on a range of topics and issues.
						</div>
				</div>
		</div>
);

export default SpeakersBanner;
