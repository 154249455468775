import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from 'src/App';

let app = (
  <div><App /></div>
)

ReactDOM.render(app, document.getElementById('root'));

