import React from 'react';

import './Modal.less';

const Modal = (props) => {
    const {
        handleClose,
        children,
        avatar,
        title,
        role
    } = props;

    return (
        <div className="modal">
            <section className="modal-main">
                <div className="modal-content">
                    <div className="modal-content-top">
                        <div className="modal-summit-speakers-avatar">
                            <img src={avatar} alt={title} />
                        </div>
                        <div className="modal-summit-speakers-title">
                            <div className="summit-speakers-title">
                                { title }
                            </div>
                            <div className="summit-speakers-role">
                                { role }
                            </div>
                        </div>
                        <div
                            className="modal-close"
                            onClick={handleClose}
                            role="button"
                            onKeyDown={() => {}}
                            tabIndex="0">
                        </div>
                    </div>
                    <div className="modal-content-bottom">
                        { children }
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Modal;
