import React from 'react';

import './SummitOrganizers.less';

import OrganizersLogo1 from 'assets/Art1st Logo-01@2x.png';
import OrganizersLogo2 from 'assets/IGNCA LOGO New@2x.png';
import OrganizersLogo3 from 'assets/cbse-logo@2x.png';

const OrganizersLogo = () => (
    <div className="organizers-logo-ctr">
    		<h2 className="organizers-logo-title">Organizers</h2>
				<div className="organizers-logo-content">
						<div className="logo-1">
								<img src={OrganizersLogo1} alt="Art Education Summit" />
						</div>
						<div className="logo-2">
								<img src={OrganizersLogo2} alt="Art Education Summit" />
						</div>
						<div className="logo-3">
								<img src={OrganizersLogo3} alt="Art Education Summit" />
						</div>
				</div>
    </div>
);

export default OrganizersLogo;
