import React from 'react';

import SummitOrganizersImage from 'assets/about/orgTeam.jpg';

const SummitOrganizersContent = () => (
    <div className="summit-organizers-content-ctr">
				<div className="summit-organizers-content">
						<h3 className="summit-organizers-content-heading">About Art1st</h3>
						<p className="summit-organizers-content-desc">
								Art1st advocates the importance of visual art education as a core discipline
								in public and private schools, museum education and community-based
								settings. At Art1st we are committed to providing an inclusive arts education
								program, the universalization of culture knowledge, and providing access to
								cultural resources to all.
						</p>
						<p className="summit-organizers-content-desc">
								We attempt to radically reimagine pedagogy, creativity, visual literacy and
								cultural awareness through series of projects such as publishing childre`s
								books, film making, workshops, and seminars on art education.
						</p>
						<a href="https://art1st.co.in" target="_blank" className="summit-organizers-content-link">
								Link to website
						</a>
						<h3 className="summit-organizers-content-heading">About Indira Gandhi National Centre for the Arts(IGNCA)</h3>
						<p className="summit-organizers-content-desc">
								The Indira Gandhi National Centre for the Arts, established in memory of Smt.
								Indira Gandhi, is visualized as a centre encompassing the study and
								experience of all the arts - each form with its own integrity, yet within a
								dimension of mutual interdependence, interrelated with nature, social
								structure and cosmology. Through diverse programs of research, publication,
								training, creative activities and performance, the IGNCA seeks to place the
								arts within the context of the natural and human environment. The
								fundamental approach of the Centre is all its work will be both
								multidisciplinary and interdisciplinary.
						</p>
						<a href="http://ignca.gov.in/" target="_blank" className="summit-organizers-content-link">
								Link to website
						</a>
						<img src={SummitOrganizersImage} alt="Banner Image" className="summit-organizers-content-image"/>
						<h3 className="summit-organizers-content-heading">About Central Board of Secondary Education(CBSE)</h3>
						<p className="summit-organizers-content-desc">
								The main objectives of CBSE are to define appropriate approaches of
								academic activities to provide stress free, child centered and holistic education
								to all the children without compromising on quality, to analyze and monitor the
								quality of academic activities by collecting the feedback from different
								stakeholders, to develop norms for implementation of various academic
								activities including quality issues; to control and coordinate the implementation
								of various academic and training programs of the Board.
						</p>
						<a href="http://cbse.nic.in/newsite/index.html" target="_blank" className="summit-organizers-content-link">
								Link to website
						</a>
				</div>
		</div>
);

export default SummitOrganizersContent;
