import React from 'react';

import RegisterImage from 'assets/Rectangle 64@2x.png';

const RegisterBanner = () => (
    <div className="register-banner-ctr">
    		<img src={RegisterImage} alt="Banner Image"/>
				<div className="register-banner-content">
						<div className="heading">Register Here</div>
						<div className="sub-heading">
								Registration Fee per attendee for 4 days: Rs. 3,000/-
						</div>
				</div>
		</div>
);

export default RegisterBanner;
