import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import day1Data from 'data/planner/day1.js';
import day2Data from 'data/planner/day2.js';
import day3Data from 'data/planner/day3.js';
import SessionRows from './SessionRows';
import './DayPlanner.less';
import DayTable from './DayTable';

class DayPlanner extends Component {
  constructor (props) {
    super(props);
    this.state = {
      guestName: '',
      email: '',
      day1morning: '',
      day1afternoon: '',
      isDay1Full: false,
      day2morning: '',
      day2afternoon: '',
      isDay2Full: false,
      day3morning: '',
      day3afternoon: '',
      isDay3Full: false,
      registrationId: "",
      saving: false,
      error: ""
    };
    this.handler = this.handler.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.slots = {};
  }

	handler(e, context) {
    console.log(e.currentTarget.id, e.currentTarget.value, context.item);
    this.setState({
      [e.currentTarget.id] : e.currentTarget.value
    });
    if (context.slot === "morning") {
      if (context.item.isFullDay) {
        this.setState({
          [`day${context.day}afternoon`] : e.currentTarget.value,
          [`isDay${context.day}Full`] : true
        });
        //this.toggleRadios(context.day, context.slot, false);
      } else {
        if (this.state[`isDay${context.day}Full`]) {
          this.setState({
            [`day${context.day}afternoon`] : "",
            [`isDay${context.day}Full`] : false
          });
          //this.toggleRadios(context.day, context.slot, true);
        }
      }
    }
    
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  validateForm() {
    let error = "";
    if (!(this.state.guestName && this.state.email)) {
      error = "Please enter your name and email address.";
      return error;
    }  

    if (!(this.state.day1morning &&
      this.state.day1afternoon &&
      this.state.day2morning &&
      this.state.day2afternoon &&
      this.state.day3morning &&
      this.state.day3afternoon)) {
        error = "Please select at least one option for each time slot.";
        return error;
    }

    return error;
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    let error = this.validateForm();
    this.setState({error: error});
    if (error) {
      return;
    }

    this.setState({saving: true});
    const formUrl = 'https://us-central1-art1st-education-summit.cloudfunctions.net/registerChoice';
    const formData = this.state;
    axios.post(
      formUrl,
      formData,
      { headers: { 'content-type': 'application/json' } },
    ).then((res) => {
      if(res && res.data) {
        this.setState({
          registrationId: res.data,
          error: "",
          saving: false
        });
        setTimeout(() => {
          window.scrollTo({
            top: 545,
            left: 0,
            behavior: 'smooth'
          });
        }, 200);
      }
      console.log(res);
    }).catch((e) => {
      console.log(e);
      this.setState({saving: false});
    });
  }

  componentDidMount() {
    let url = 'https://art1st-education-summit.firebaseio.com/slots.json';
    return axios.get(url, {
        headers: {
            authorization: null,
        },
    }).then((response) => {
        const res = response.data;
        this.slots = res;
        this.forceUpdate();
    }).catch((error) => {
        console.log(error);
    });
  }

  render() {
    let dataTableDay1 = day1Data.morning.map((morningItem, idx) => <SessionRows slots={this.slots} key={morningItem.id} handler={this.handler} morningItem={morningItem} afternoonItem={day1Data.afternoon[idx]} day="1" isDayFull={this.state.isDay1Full} />);
    let day1PlannerForm = <DayTable dataTable={dataTableDay1} />;

    let dataTableDay2 = day2Data.morning.map((morningItem, idx) => <SessionRows slots={this.slots} key={morningItem.id} handler={this.handler} morningItem={morningItem} afternoonItem={day2Data.afternoon[idx]} day="2" isDayFull={this.state.isDay2Full}  />);
    let day2PlannerForm = <DayTable dataTable={dataTableDay2} />;

    let dataTableDay3 = day3Data.morning.map((morningItem, idx) => <SessionRows slots={this.slots} key={morningItem.id} handler={this.handler} morningItem={morningItem} afternoonItem={day3Data.afternoon[idx]} day="3" isDayFull={this.state.isDay3Full}  />);
    let day3PlannerForm = <DayTable dataTable={dataTableDay3} />;

    let successForm = (
			<div className="success-page">
				<div className="success-form">
        Thank you for selecting your sessions at the Art Education Summit 2019. Your registration number is:<br />{this.state.registrationId}.
				</div>
        <p className="success-info">
          To ensure that the Summit goes smoothly, please make sure to go directly to the session you have selected. The Seminar Sessions are in the Hall, while the Labs are in the Gallery. There will be signs and volunteers at the venue to help you navigate.
        </p>
			</div>
		);
    
    let form = (
      <div className="planner-form-ctr">
        <div className="registration-form-text">
            <div className="bold-text">Welcome to the Art Education Summit 2019</div>
            <p> Please select the sessions you would like to attend. A few things to make note of before you start:</p>
            <ul>
              <li>This form is only for confirmed participants. If you haven’t registered, your response will not be accepted</li>
              <li>Fill in your full name as well as the email id used during registrations- you may face an error if you fill in another email id</li>
              <li>Read through the schedule and modules on the website to decide which session you would like to participate in. The sessions have a limited number of seats, so a registration will be made on a first-come, first-serve basis</li>
              <li>Please read the timings and session names carefully before making your selection. It cannot be changed once the form is submitted.</li>
              <li>Don’t worry if the sessions of your choice are full. We have planned engaging walk-in sessions and exhibitions that are open through the day.</li>
              <li>In case of any queries or assistance during this process, please email us at artsummit@art1st.co.in</li>
            </ul>
        </div>
        <form onSubmit={this.handleSubmit}>
          <label>
              <span>Name:</span>
              <input
                  name="guestName"
                  type="text"
                  value={this.state.guestName}
                  onChange={this.handleInputChange} />
          </label>
          <br />
          <label>
              <span>Email:</span>
              <input
                  name="email"
                  type="email"
                  placeholder="(Please provide the email address used to register)"
                  value={this.state.email}
                  onChange={this.handleInputChange} />
          </label>
          <h1>Day 1</h1>
          {day1PlannerForm}
          <br />
          <h1>Day 2</h1>
          {day2PlannerForm}
          <br />
          <h1>Day 3</h1>
          {day3PlannerForm}
          <input type="submit" value={this.state.saving ? "SAVING..." : "SUBMIT"} className="form-submit-btn" disabled={this.state.saving ? "disabled": ""} />
          <p className={this.state.error ? "error show" : "error hide"}>{this.state.error}</p>
        </form>
      </div>
    );

    return this.state.registrationId ? successForm : form;

  }
}

export default DayPlanner;
