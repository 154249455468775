import React from 'react';
import { Route, HashRouter as Router, Switch, browserHistory } from 'react-router-dom';

import './App.css';
import Home from './components/Pages/Home';
import PaymentPage from './components/Pages/PaymentPage';
import RegisterPage from './components/Pages/RegisterPage';
import SpeakersPage from './components/Pages/SpeakersPage';
import SchedulePage from './components/Pages/SchedulePage';
import AboutPage from './components/Pages/AboutPage';
import OrganisersPage from './components/Pages/OrganisersPage';
import InteractiveLabsPage from './components/Pages/InteractiveLabsPage';
import ChildArtMuseumPage from './components/Pages/ChildArtMuseumPage';
import FAQPage from './components/Pages/FAQPage';
import Header from './components/Header/Header';
import HeaderMobile from './components/Header/HeaderMobile';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import RegistrationUpdate from './components/Pages/RegistrationUpdate';

library.add(faInfoCircle);

const App = () => (
		<div className="App">
				<div className="show-in-desktop">
						<Router onUpdate={() => window.scrollTo(0, 0)}>
							<Header />
							<Route exact path="/" component={Home} />
							<Route path="/payment" component={PaymentPage} />
							<Route path="/register" component={RegisterPage} />
							<Route path="/speakers" component={SpeakersPage} />
							<Route path="/schedule" component={SchedulePage} />
							<Route path="/about" component={AboutPage} />
							<Route path="/organisers" component={OrganisersPage} />
							<Route path="/ilabs" component={InteractiveLabsPage} />
							<Route path="/childartmuseum" component={ChildArtMuseumPage} />
							<Route path="/help" component={FAQPage} />
							<Route path="/plan" component={RegistrationUpdate} />
						</Router>
				</div>
				<div className="show-in-mobile">
						<Router>
								<HeaderMobile />
								<Route exact path="/" component={Home} />
								<Route path="/payment" component={PaymentPage} />
								<Route path="/register" component={RegisterPage} />
								<Route path="/speakers" component={SpeakersPage} />
								<Route path="/schedule" component={SchedulePage} />
								<Route path="/about" component={AboutPage} />
								<Route path="/organisers" component={OrganisersPage} />
								<Route path="/ilabs" component={InteractiveLabsPage} />
								<Route path="/childartmuseum" component={ChildArtMuseumPage} />
								<Route path="/help" component={FAQPage} />
								<Route path="/plan" component={RegistrationUpdate} />
						</Router>
				</div>
		</div>
);

export default App;
