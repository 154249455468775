import React, { Component } from 'react';
import axios from 'axios';

import Modal from './Modal';
import './SpeakerList.less';

class FacilitatorsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        		show: false,
        		speakers: [],
        		currentSpeaker: {},
				};
				this.showModal = this.showModal.bind(this);
				this.handleClose = this.handleClose.bind(this);
    };

		componentWillUnmount() {
				document.removeEventListener('mousedown', this.handleClose, false);
				document.removeEventListener('keydown', this.handleClose, false);
		}

		componentDidMount() {
				let url = 'https://art1st-education-summit.firebaseio.com/facilitators.json';
				return axios.get(url, {
						headers: {
								authorization: null,
						},
				}).then((response) => {
						const res = response.data;
						this.setState({
								speakers: res ? res : [],
						});
				}).catch((error) => {
						console.log(error);
				});
				document.addEventListener('mousedown', this.handleClose, false);
				document.addEventListener('keydown', this.handleClose, false);
		}

    showModal(currentSpeaker) {
        this.setState({
						show: true,
						currentSpeaker,
				});
    };

    handleClose() {
        this.setState({
						show: false,
				});
    };

    render() {
    		const { speakers, show, currentSpeaker } = this.state;
    		let speakersTemplate = '';
    		if (speakers && speakers.length > 0) {
						speakersTemplate = speakers.map(speaker => {
							let avatarStyle = {backgroundImage: `url(${speaker.imagePath}`};
							return (
								<div className="summit-speakers-item" key={ speaker.title }>
										<div className="summit-speakers-avatar" style={avatarStyle}>
												{/* <img src={speaker.imagePath} alt={ speaker.title } /> */}
										</div>
										<div className="speaker-row">
												<div className="summit-speakers-title">
														{ speaker.title }
												</div>
												<div className="summit-speakers-role">
														{ speaker.designation }
												</div>
												<div className="text modal-ctr">
														<div
																className="modal-action"
																onClick={() => this.showModal(speaker)}
																role="button"
																onKeyDown={() => {}}
																tabIndex="0">
																Read Bio
														</div>
												</div>
										</div>
								</div>
								)
							}
						);
				}
        return (
            <div className="speakers-list-ctr">
                <h2>Interactive Lab Facilitators</h2>
                <div className="summit-speakers-content">
                    <div className="summit-speakers-items">
                    		{ speakersTemplate }
                    </div>
                    {
						show && (
							<Modal
								handleClose={this.handleClose}
								avatar={currentSpeaker.imagePath}
								title={currentSpeaker.title}
								role={currentSpeaker.designation}>
								<div>{currentSpeaker.description}</div>
							</Modal>
						)
					}
                </div>
            </div>
        );
    }
}

export default FacilitatorsList;
