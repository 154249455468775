import React from 'react';

import Banner from '../Banner/Banner';
import Contact from '../Contact/Contact';
import RegistrationUpdateForm from '../RegistrationForm/RegistrationUpdateForm';
import Footer from '../Footer/Footer';
import Organizers from '../Organizers/Organizers';

import './Pages.less';

const RegistrationUpdate = () => (
    <div className="main-ctr">
        <RegistrationUpdateForm />
        <div className="desktop-layout contact-organizers-ctr">
            <div className="contact-component">
                <Contact />
            </div>
            <div className="organizers-component">
                <Organizers />
                <Footer />
            </div>
        </div>
    </div>
);

export default RegistrationUpdate;
