import React from 'react';

import SummitOrganizers from '../SummitOrganizers/SummitOrganizers';
import './Pages.less';

const OrganisersPage = () => (
    <div className="main-ctr">
        <SummitOrganizers />
    </div>
);

export default OrganisersPage;
