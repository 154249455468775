import React from 'react';

import ChildArtMuseumImage1 from 'src/assets/3E_17_Vedant.-Sharma-3E-line-landscape@2x.png';
import ChildArtMuseumImage2 from 'assets/Screen Shot 2019-10-05 at 10.04.50 PM@2x.png';
import ChildArtMuseumImage3 from 'assets/3E_17_Vedant.-Sharma-3E-line-landscape@2x.png';
import ChildArtMuseumImage4 from 'assets/IMG_0431@2x.png';
import ChildArtMuseumImage5 from 'assets/Ajaybeer-e1570188301318@2x.png';
import ChildArtMuseumImage6 from 'assets/Corpse@2x.png';
import ChildArtMuseumImage7 from 'assets/childart/IMG-20180810-WA0037@2x.jpg';
import ChildArtMuseumImage8 from 'assets/childart/IMG-20180215-WA0003@2x.jpg';
import ChildArtMuseumImage9 from 'assets/childart/IMG-20180824-WA0001@2x.jpg';
import ChildArtMuseumImage10 from 'assets/55548576_2289763541082132_4261104197585338368_o.png';
import ChildArtMuseumImage11 from 'assets/Group 243.png';

const ChildArtMuseumContent = () => (
    <div className="child-art-content-ctr">
				<div className="child-art-content">
						<img src={ChildArtMuseumImage10} alt="Image 1" />
						<div className="child-art-row-1">
								<div className="child-art-row-1-head">The Imaginarium: Child Art Exhibition </div>
								<p>
										Through this exhibition Art1st contextualists creativity for our times and try to
										understand its relevance better for growth of the child. The exhibition will feature works
										which will enable us to understand the thinking processes of the child and the conditions
										which leads to creativity. It will chow how the child thinks in an information technology
										driven world and how do they respond to their times.
								</p>
								<p>
										The child art exhibition foregrounds the vision of Art1st Foundation in giving a new direction
										towards understanding childhood, creativity and imagination. It positions child at the center
										of learning Arts and use this platform to show how the Art1st curriculum enables the child in realizing
										the true potentials of creativity and imagination. Departing from conventional art exhibitions of children
										which attempt to create spectacles out of the children's works, this show brings together
										the creative journey of the child in an organic manner.
								</p>
								<p className="bold-text">
								Walkthroughs of the exhibit will be organized each day at 12:00 pm and 03:00 pm. Parents and teachers are invited to the walkthrough conducted by the Art1st team. You can now upload your child's artworks to the online Child Art Museum curated specially by Art1st.
									&nbsp; <a href="http://museum.art1st.co.in/" target="_blank">Submit here</a>.
								</p>
							
								<div className="child-art-row-1-head">Child Art Workshop</div>
								<p>
										At the heart of any conversation on art education is the child.
										We shall celebrate their imagination and creativity through a series of workshops facilitated by young artists.
										The children are invited to interact with books and art, as they enjoy storytelling sessions,
										learn about artists and begin creating their own art.
								</p>
								<div className="child-art-row-1-sub-head">Workshop 01</div>
								<p>
										Day 1 | Dec 18 | 10.30 am - 12.30 pm | No. of children: 30
								</p>
								<p>
										<img className="child-art-row-1-img" src={ChildArtMuseumImage3} alt="Child Art Museum Image3" />
								</p>
								<div className="child-art-row-1-sub-head">Workshop 02</div>
								<p>
										Day 1 | Dec 18 | 03.00 pm - 05.00 pm | No. of children: 30
								</p>
								<p>
										<img className="child-art-row-1-img" src={ChildArtMuseumImage4} alt="Child Art Museum Image3" />
								</p>
								<div className="child-art-row-1-sub-head">Workshop 03</div>
								<p>
										Day 2 | Dec 19 | 10.30 am - 12.30 pm | No. of children: 30
								</p>
								<p>
										<img className="child-art-row-1-img" src={ChildArtMuseumImage5} alt="Child Art Museum Image5" />
								</p>
								<div className="child-art-row-1-sub-head">Workshop 04</div>
								<p>
										Day 2 | Dec 19 | 02.00 pm - 04.00 pm | No. of children: 30
								</p>
								<p>
										{/* <img className="child-art-row-1-img-1" src={ChildArtMuseumImage6} alt="Child Art Museum Image5" /> */}
										<img className="child-art-row-1-img" src={ChildArtMuseumImage7} alt="Child Art Museum Image5" />
								</p>
								<div className="child-art-row-1-sub-head">Workshop 05</div>
								<p>
										 Day 3 | Dec 20 | 10.30 am - 12.30 pm | No. of children: 30
								</p>
								<p>
										<img className="child-art-row-1-img" src={ChildArtMuseumImage8} alt="Child Art Museum Image5" />
								</p>
								<div className="child-art-row-1-sub-head">Workshop 06</div>
								<p>
										Day 3 | Dec 20 | 02.00 pm - 04.00 pm | No. of children: 30
								</p>
								<p>
										<img className="child-art-row-1-img" src={ChildArtMuseumImage9} alt="Child Art Museum Image5" />
								</p>
						</div>
				</div>
		</div>
);

export default ChildArtMuseumContent;
