import React from 'react';

import AboutBanner from './AboutBanner';
import AboutContent from './AboutContent';
import './About.less';

const About = () => (
    <div className="about-ctr">
				<AboutBanner />
				<AboutContent />
		</div>
);

export default About;
