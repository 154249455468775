import React from 'react';

import Banner from '../Banner/Banner';
import Intro from '../Intro/Intro';
import PolicyMakers from '../PolicyMakers/PolicyMakers';
import Principals from '../Principals/Principals';
import Teachers from '../Teachers/Teachers';
import Parents from '../Parents/Parents';
import Register from '../Register/Register';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';
import Address from '../Address/Address';
import SummitSpeakers from '../SummitSpeakers/SummitSpeakers';
import InteractiveLabs from '../InteractiveLabs/InteractiveLabs';
import ChildArt from '../ChildArt/ChildArt';
import KitabCorner from '../KitabCorner/KitabCorner';
import Organizers from '../Organizers/Organizers';
import FooterMobile from '../Footer/FooterMobile';

import './Pages.less';

const Home = () => (
    <div className="main-ctr">
        <Banner />
        <div className="desktop-layout intro-address-ctr">
            <div className="intro-component">
                <Intro />
            </div>
            <div className="address-component">
                <Address />
            </div>
        </div>
        <div className="desktop-layout policy-principal-ctr">
            <div className="policy-markers-component">
                <PolicyMakers />
            </div>
            <div className="principals-component">
                <Principals />
            </div>
        </div>
        <div className="desktop-layout teachers-parents-ctr">
            <div className="teachers-component">
                <Teachers />
            </div>
            <div className="parents-component">
                <Parents />
            </div>
        </div>
        {/* <Register /> */}
        <KitabCorner />
        <SummitSpeakers />
        <InteractiveLabs />
        <ChildArt />
        <div className="desktop-layout contact-organizers-ctr">
            <div className="contact-component">
                <Contact />
            </div>
            <div className="show-in-desktop">
								<div className="organizers-component">
										<Organizers />
										<Footer />
								</div>
						</div>
						<div className="show-in-mobile">
								<FooterMobile />
						</div>
        </div>
    </div>
);

export default Home;
