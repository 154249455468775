import React from 'react';

import FAQBanner from './FAQBanner';
import './FAQ.less';

const FAQ = () => {
    return (
        <div className="faq-info-ctr">
						<FAQBanner />
						<div className="faq-content-ctr">
								<div className="faq-content-items">
										<div className="faq-content-item">
												<div className="faq">
														1. Is it a paid event? What is the registration fee?
												</div>
												<div className="faq-answer">
														Yes, all participants have to pay the fee of Rs 3,000 in order to register.
														This fee is inclusive of all 3 days of the summit.
												</div>
										</div>
										<div className="faq-content-item">
												<div className="faq">
														2. Can we register and pay at the venue?
												</div>
												<div className="faq-answer">
														As there is a limit to the number of seats, you must register
														through the website to be able to attend the summit.
												</div>
										</div>
										<div className="faq-content-item">
												<div className="faq">
														3. Can we only pay by NEFT or cheque?
												</div>
												<div className="faq-answer">
														Yes; you can only pay by NEFT or cheque to the Art1st Foundation.
														If you’re having any issues with payments, please write to priyam@art1st.co.in
												</div>
										</div>
										<div className="faq-content-item">
												<div className="faq">
														4. What time does the event start and end?
												</div>
												<div className="faq-answer">
														Each day, the event begins at 10.00 am and ends at 6:00 pm. Registrations at the venue will happen by 09.15-9:45 am
												</div>
										</div>
										<div className="faq-content-item">
												<div className="faq">
														5. Do we need to carry any school ID for registration?
												</div>
												<div className="faq-answer">
														Yes. Do carry your School ID, Adhaar Card or PAN card.
												</div>
										</div>
										<div className="faq-content-item">
												<div className="faq">
														6. Does the fee include travel and stay?
												</div>
												<div className="faq-answer">
														No it doesn’t. You must take care of your own stay and local travel to the venue.
												</div>
										</div>
										<div className="faq-content-item">
												<div className="faq">
														7. Do the attendees get a participation certificate?
												</div>
												<div className="faq-answer">
														Yes, every attendee will get an e-participation certificate to be mailed to them at the end of the summit.
												</div>
										</div>
										<div className="faq-content-item">
												<div className="faq">
														8. Is it only for an art teacher or a math teacher can come too?
												</div>
												<div className="faq-answer">
														The summit is open to all teachers including art and other subject teachers, as well as any other individual that is interested.
												</div>
										</div>
										<div className="faq-content-item">
												<div className="faq">
														9. Is it compulsory to enrol through the school? Can an individual enrol too?
												</div>
												<div className="faq-answer">
														You can register as an individual or through your school. Do make sure you don’t register twice.
												</div>
										</div>
										<div className="faq-content-item">
												<div className="faq">
														10. How do I reach IGNCA locally?
												</div>
												<div className="faq-answer">
														The nearest Delhi Metro Station is Central Secretariat, located on the Yellow and
														Violet Metro Lines. After exiting from the metro exit gate 2, you can walk for
														10 mins to reach IGNCA. For directions &nbsp;
														<a href="https://www.google.com/maps/dir/Central+Secretariat+Metro+Station+Gate+Number+3,+New+Delhi,+Delhi/Indira+Gandhi+National+Centre+for+the+Arts,+11,+Man+Singh+Rd,+near+Andhra+Bhavan,+Rajpath+Area,+Central+Secretariat,+New+Delhi,+Delhi+110011/@28.6151903,77.2139419,17z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x390ce2b6c5671525:0xea576b0205e031c3!2m2!1d77.2125206!2d28.6151124!1m5!1m1!1s0x390ce2c9af3dd147:0x6bf14eaeb73ba5e4!2m2!1d77.2200559!2d28.6154803!3e2">click here</a>
												</div>
										</div>
								</div>
						</div>
        </div>
    );
};

export default FAQ;
