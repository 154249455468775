import React from 'react'

import ChildArtMuseumBanner from './ChildArtMuseumBanner'
import ChildArtMuseumContent from './ChildArtMuseumContent'
import './ChildArtMuseum.less'
import SupportedBy from '../SupportedBy/SupportedBy'

import ImgB1 from 'assets/Organizers/supported/HD Millennium_Logo__2_.png'
import ImgB2 from 'assets/Organizers/supported/High Resolution school logo latest 2019.png'
import ImgB3 from 'assets/Organizers/supported/PSBB LOGO.png'
import ImgB4 from 'assets/Organizers/supported/psbblogo_new.png'
import ImgB5 from 'assets/Organizers/supported/PSBBM-OMR-LOGO.png'
import ImgB6 from 'assets/Organizers/supported/PSBBMCBE Logo copy.png'
import ImgB7 from 'assets/Organizers/supported/ShriLogo.png'
import ImgB8 from 'assets/Organizers/supported/Shriram Logo.png'
import ImgB9 from 'assets/Organizers/supported/TUSS_Hyderabad.png'
import ImgB10 from 'assets/Organizers/supported/TES Logo.png'
import ImgS1 from 'assets/Organizers/supported/HEXAWARE LOGO.png'
import ImgS2 from 'assets/Organizers/supported/FINAL KNMA - LOGO-01.png'
import ImgS3 from 'assets/Organizers/supported/FlowIndia_Logo-01.png'
import ImgS4 from 'assets/Organizers/supported/spf_fin.png'
import ImgS5 from 'assets/Organizers/supported/akar-prakar-retina-logo.png'
import ImgS6 from 'assets/Organizers/supported/ojasartlogo.png'
import ImgS7 from 'assets/Organizers/supported/stationary-memories-camlin-story.png'

const ChildArtMuseum = () => {

	const broughtToList = [
		{ title: '', image: ImgB1, id: 1 },
		{ title: '', image: ImgB2, id: 2 },
		{ title: '', image: ImgB3, id: 3 },
		{ title: '', image: ImgB4, id: 4 },
		{ title: '', image: ImgB5, id: 5 },
		{ title: '', image: ImgB6, id: 6 },
		{ title: '', image: ImgB7, id: 7 },
		{ title: '', image: ImgB8, id: 8 },
		{ title: '', image: ImgB9, id: 9 },
		{ title: '', image: ImgB10, id: 10 }
	]
	const supportedByList = [
		{title: '', image: ImgS1, id: 21 },
		{title: '', image: ImgS2, id: 22 },
		{title: '', image: ImgS3, id: 23 },
		{title: '', image: ImgS4, id: 24 },
		{title: '', image: ImgS5, id: 25 },
		{title: '', image: ImgS6, id: 26 },
		{title: '', image: ImgS7, id: 27 }
	]

	return (
		<div className="child-art-museum-ctr">
			<ChildArtMuseumBanner />
			<ChildArtMuseumContent />
			<SupportedBy support={supportedByList} brought={broughtToList}/>
		</div>
	)
}

export default ChildArtMuseum
