import React from 'react';

const DayTable = (props) => (
    <table className="planner-table">
        <thead>
        <tr>
          <th>Time/Session</th>
          <th>Morning (10:30 am - 1:00 pm)</th>
          <th>Selected Option</th>
          {/* <th>Session</th> */}
          <th>Afternoon (02:00 pm - 6:00 pm)</th>
          <th>Selected Option</th>
        </tr>
        </thead>
        <tbody>
        {props.dataTable}
        </tbody>
      </table>
);

export default DayTable;
