import React from 'react';

import ILabsBanner from './ILabsBanner';
import ILabsContent from './ILabsContent';
import OwnLabs from './OwnLabs';
import SupportedBy from '../SupportedBy/SupportedBy';
import './ILabs.less';

import ImgB1 from "assets/Organizers/supported/ArtScienceBLR.png";
import ImgB2 from "assets/Organizers/supported/FlowIndia_Logo-1.png";
import ImgB3 from "assets/Organizers/supported/Partner A Master- Master Logo.png";
import ImgB4 from "assets/Organizers/supported/Art1st_Logo-01.png";
import ImgB5 from "assets/Organizers/supported/TinkerLabs Logo square@2x.png";
import ImgB6 from "assets/Organizers/supported/QuicksandLOGO@2x.png";
import ImgB7 from "assets/Organizers/supported/d-Van@2x.png";
import ImgS1 from "assets/Organizers/supported/HEXAWARE LOGO.png";
import ImgS2 from "assets/Organizers/supported/FINAL KNMA - LOGO-01.png";
import ImgS3 from "assets/Organizers/supported/FlowIndia_Logo-01.png";
import ImgS4 from "assets/Organizers/supported/spf_fin.png";
import ImgS5 from "assets/Organizers/supported/akar-prakar-retina-logo.png";
import ImgS6 from "assets/Organizers/supported/ojasartlogo.png";

const ILabs = () => {
	const broughtToList = [
		{ title: '', image: ImgB1, id: 1 },
		{ title: '', image: ImgB2, id: 2 },
		{ title: '', image: ImgB3, id: 3 },
		{ title: '', image: ImgB4, id: 4 },
		{ title: '', image: ImgB5, id: 5 },
		{ title: '', image: ImgB6, id: 6 },
		{ title: '', image: ImgB7, id: 7 }
	]
	const supportedByList = [
		{title: '', image: ImgS1, id: 21 },
		{title: '', image: ImgS2, id: 22 },
		{title: '', image: ImgS3, id: 23 },
		{title: '', image: ImgS4, id: 24 },
		{title: '', image: ImgS5, id: 25 },
		{title: '', image: ImgS6, id: 26 }
	]

 return ( <div className="ilabs-ctr">
				<ILabsBanner />
				<ILabsContent />
				<OwnLabs />
				<SupportedBy support={supportedByList} brought={broughtToList} />
		</div>
	);
}
export default ILabs;
