import React from 'react';
import { Link } from "react-router-dom";

import ChildArtImage from 'src/assets/3E_17_Vedant.-Sharma-3E-line-landscape@2x.png';
import './ChildArt.less';

const ChildArt = () => (
    <div className="child-art-ctr">
        <div className="child-art-title">
            Child Art Museum
        </div>
        <div className="child-art-content">
        		<div className="child-art-content-left">
        				<div className="bold-text">
										The Imaginarium
								</div>
								<div className="text">
										The children's art exhibition will be curated with the art works of students from schools that
										have implemented Art1st's Art Education Program.
								</div>
								<div className="bold-text">
										Children's Workshops
								</div>
								<div className="text">
										Over the 3 days, art teachers will conduct workshops based on Art1st's Art
										Education Program and Art1st Books for children across various age-groups.
								</div>
								<div className="child-art-redirect">
										<Link to="/childartmuseum">Find out More</Link>
								</div>
        		</div>
						<div className="child-art-content-right">
								<img src={ChildArtImage} alt="Child Art Image" />
						</div>
        </div>
    </div>
);

export default ChildArt;
