import React from 'react';
import { Link } from "react-router-dom";

import KeyNoteSpeakerList from './SpeakerList/KeyNoteSpeakerList';
import PanelistsList from './SpeakerList/PanelistsList';
import FacilitatorsList from './SpeakerList/FacilitatorsList';
import SpeakersBanner from './SpeakersBanner';
import './Speakers.less';

const Speakers = () => (
    <div className="speakers-ctr">
        <SpeakersBanner />
        <KeyNoteSpeakerList />
        <FacilitatorsList />
    </div>
);

export default Speakers;
