import React, { Component } from 'react';

import Option from './Option';
import './Select.less';

class Select extends Component{

		constructor(props){
				super(props);
				this.onOpen = this.onOpen.bind(this);
				this.onSelect = this.onSelect.bind(this);
				this.state = {
						selected: null,
						opened: false
				};
		}

		onOpen(){
				this.setState({
						opened: !this.state.opened
				});
		}

		onSelect(option){
				this.setState({
						selected: option,
						opened: false
				}, ()=> {
						const obj = {
								key: this.props.name,
								value: this.state.selected.key
						}
						this.props.handleSelectInputChange(obj);
				});
		}

		getOptions(){
				return this.props.options.map( o => <Option key={o.key} option={o} onSelect={this.onSelect} />);
		}

		render(){
				let items = this.getOptions();
				let selected = this.state.selected ?
						this.state.selected.value : this.props.label;
				let cssClass = this.state.opened ? `show ${this.props.customClass}`: 'hide';
				let parentClass = this.props.customParentClass ? `custom-select-component ${this.props.customParentClass}` : 'custom-select-component'

				return (
						<div className={parentClass} onClick={this.onOpen}>
								<div>
										{selected}
								</div>
								<ul className={cssClass}>{items}</ul>
						</div>
				);
		}
}

export default Select;
