import React from 'react';

import Speaker1 from 'src/assets/Speakers/SSamant.jpg';
import Speaker2 from 'src/assets/Speakers/ggs.jpg';
import Speaker3 from 'src/assets/Speakers/ak.jpg';
import Speaker4 from 'src/assets/Speakers/HS.jpg';
import Speaker5 from 'src/assets/Speakers/Shashi.jpg';
import Speaker6 from 'src/assets/Speakers/Arundhati-Ghosh---ED-IFA-High-res.jpg';
import Speaker7 from 'src/assets/Speakers/Shukla-Sawant.jpg';
import Speaker8 from 'src/assets/Speakers/IshanKhosla_Japan.jpg';
import Speaker9 from 'src/assets/Speakers/Bhajju Shyam.jpeg';
import Speaker10 from 'src/assets/Speakers/mk.jpg';
import Speaker11 from 'src/assets/Speakers/TGT.jpg';
import Speaker12 from 'src/assets/Speakers/agSingh.jpg';
import Speaker13 from 'src/assets/Speakers/Dr. Rakesh Batabyal.gif';
import Speaker14 from 'src/assets/Speakers/Avishek-Ray.jpg';
import Speaker15 from 'src/assets/Speakers/TimiraGupta.jpg';



const DayOneContent = () => (
		<div className="day-content-ctr">
				<div className="day-content">
						<div className="day-intro-content">
								<div className="day-row day-row-mobile">
										<div className="day-row-head">Introductory Speech</div>
										<div className="day-row-text">
												<span>10.30 am – 10.45 am</span>
												<span> | </span>
												<span>Auditorium</span>
										</div>
								</div>
								<div className="day-row">
										<div className="day-row-main-head">Contextualizing Creativity: <br />Modules of Visual Thinking, Art Teaching and Learning</div>
								</div>
								<div className="day-row">
										<div className="speaker-image">
												<img src={Speaker1} alt="Sharmila Samant" />
										</div>
										<div className="day-row-content">
												<div className="speaker-name">Sharmila Samant</div>
												<div className="speaker-role">Visual Artist & Advisor, Art1st </div>
										</div>
								</div>
						</div>
						<div className="day-intro-content day-key-note-content">
								<div className="day-row day-row-mobile">
										<div className="day-row-head">Keynote Address</div>
										<div className="day-row-text">
												<span>10.45 am – 11.30 am</span>
												<span> | </span>
												<span>Auditorium</span>
										</div>
								</div>
								<div className="day-row">
										<div className="day-row-main-head">The Need of Reform in School Education in India: Research and Policy Implementation</div>
								</div>
						</div>
						<div className="day-panel-content spacer">
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Panel Discussion</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>11.45 am – 12. 30 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Integrated Arts Pedagogy: Framing Policy and Curriculums for the Future</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker3} alt="Ms. Anita Karwal " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Ms. Anita Karwal </div>
														<div className="speaker-role">Chairman, CBSE</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker4} alt="Hrushikesh Senapaty " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Hrushikesh Senapaty </div>
														<div className="speaker-role">Director, NCERT</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker5} alt="Shashi Banerjee" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Shashi Banerjee</div>
														<div className="speaker-role">Principal, Shiv Nadar School</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker6} alt="Arundhati Ghosh  " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Arundhati Ghosh  </div>
														<div className="speaker-role">Executive Director, India Foundation for the Arts</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker7} alt="Shukla Sawant" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Shukla Sawant</div>
														<div className="speaker-role">Visual Artist and Professor, School of Arts and Aesthetics, Jawaharlal Nehru University</div>
												</div>
										</div>
								</div>
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Panel Discussion</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>12.30 pm – 1.15 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Zones of Exchange: Where Living Traditions and Contemporaneity Meet in Arts Education</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker8} alt="Ishan Khosla" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Ishan Khosla</div>
														<div className="speaker-role">Artist and Founder, The Typecraft Initiative</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker9} alt="Bhajju Shyam " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Bhajju Shyam </div>
														<div className="speaker-role">Artist</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker10} alt="Molly Kaushal " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Molly Kaushal </div>
														<div className="speaker-role">Professor and Head, Janapada Sampada, IGNCA</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker11} alt="Tapati Guha Thakurta " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Tapati Guha Thakurta  </div>
														<div className="speaker-role">Historian and Author</div>
												</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker12} alt="Amrita Gupta Singh  " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Amrita Gupta Singh </div>
														<div className="speaker-role">Art Historian and Curatorial Advisor, Art1st Foundation</div>
												</div>
										</div>
								</div>
						</div>
						<div className="day-panel-content">
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Paper Presentation</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>2.00 pm - 2.30 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Art and the Republican Universe: The Modern School, Delhi, 1947-1961</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker13} alt="Dr. Rakesh Batabyal" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Dr. Rakesh Batabyal</div>
														<div className="speaker-role">Associate Professor, Centre for Media Studies, Jawaharlal Nehru University</div>
												</div>
										</div>
								</div>
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Paper Presentation</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>2.30 pm - 3.00 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">The Constituency of Folk Art: Assimilation in Contemporary Art Education</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker14} alt="Avishek Ray  " />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Avishek Ray </div>
														<div className="speaker-role">Assistant Professor, National Institute of Technology, Silchar</div>
												</div>
										</div>
								</div>
						</div>
						<div className="day-panel-content spacer">
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Best Practices :: Case Studies</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>3.00 pm – 3.30 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Project Sector 8, Akshara High School, Mumbai</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														<img src={Speaker15} alt="Timira Gupta" />
												</div>
												<div className="day-row-content">
														<div className="speaker-name">Timira Gupta</div>
														<div className="speaker-role">Executive-Director, Akshara High School</div>
												</div>
										</div>										
								</div>
								<div className="day-column">
										<div className="day-row">
												<div className="day-row-head">Thematic Workshops</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>3.30 pm – 6.00 pm</span>
														<span> | </span>
														<span>Auditorium</span>
												</div>
										</div>
										<div className="day-row">
												<div className="day-row-main-head">Interplay between Creativity, Context and Innovation: Empirical Approaches</div>
										</div>
										<div className="day-row space">
												<div className="speaker-image">
														{/* <img src="src/assets/Speakers/tb.jpg" alt="Tultul Biswas " /> */}
												</div>
												<div className="day-row-content">
														<div className="speaker-name"></div>
														<div className="speaker-role"></div>
												</div>
										</div>										
								</div>
						</div>
						<div className="day-ilbas-content-ctr">
								<div className="day-ilbas-content">
										<div className="day-column">
												<div className="day-row">
													<div className="heading">Interactive Lab</div>
												</div>
												<div className="day-row">
														<div className="day-row-text">
															<span>10.00 am – 5.00 pm</span>
															<span> | </span>
															<span>Twin Art Gallery</span>
														</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-title">Build your Own Lab</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-speaker">ArtScience BLR, Srishti Institute of Art, Design and Technology | Bengaluru</div>
												</div>
										</div>
										<div className="day-column">
												<div className="day-row">
														<div className="heading">Interactive Lab</div>
												</div>
												<div className="day-row">
														<div className="day-row-text">
																<span>10.00 am – 1.00 pm, 2:00 pm - 5:00 pm</span>
																<span> | </span>
																<span>Twin Art Gallery</span>
														</div>
												</div>
												<div className="day-row">
														<div className="day-ilab-title">Culture and Museum Learning</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-speaker">FLOW India</div>
												</div>
										</div>
										<div className="day-column">
												<div className="day-row">
														<div className="heading">Interactive Lab</div>
												</div>
												<div className="day-row">
														<div className="day-row-text">
															<span>10.00 am – 1.00 pm, 2:00 pm - 5:00 pm</span>
															<span> | </span>
															<span>Twin Art Gallery</span>
														</div>
												</div>
												<div className="day-row">
														<div className="day-ilab-title">Creative Learning and Practice (CLaP)</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-speaker">Council for Arts and Social Practice (CASP)</div>
												</div>
										</div>
										<div className="day-column">
												<div className="day-row">
														<div className="heading">Interactive Lab</div>
												</div>
												<div className="day-row">
													<div className="day-row-text">
														<span>10.00 am – 1.00 pm, 2:00 pm - 5:00 pm</span>
														<span> | </span>
														<span>Twin Art Gallery</span>
													</div>
												</div>
												<div className="day-row">
														<div className="day-ilab-title">Partner a Master: Artist-Mentor Program</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-speaker">Sonal Jain, Artist and Co-Founder, Desire Machine Collective, Guwahati</div>
												</div>
										</div>
										<div className="day-column">
												<div className="day-row">
														<div className="heading">Interactive Lab</div>
												</div>
												<div className="day-row">
													<div className="day-row-text">
														<span>10.00 am – 1.00 pm, 2:00 pm - 5:00 pm</span>
														<span> | </span>
														<span>Twin Art Gallery</span>
													</div>
												</div>
												<div className="day-row">
														<div className="day-ilab-title">UNBOX: An approach to Design Thinking</div>
												</div>
												<div className="day-row">
													<div className="day-ilab-speaker">Quicksand</div>
												</div>
										</div>
								</div>
						</div>
						<div className="day-child-art-ctr">
								<div className="day-column">
										<div className="day-row">
												<div className="heading">Child Art Workshop</div>
										</div>
										<div className="day-row">
												<div className="day-row-text">
														<span>10.30 am - 12.30 pm</span>
														<span> | </span>
														<span>Amphitheatre</span>
												</div>
										</div>
										<div className="day-row-main-head">
												<div className="day-row-sub-head">Workshop 01</div>
										</div>
								</div>
								<div className="day-column">
										<div className="day-row">
												<div className="heading">Child Art Workshop</div>
										</div>
										<div className="day-row">
											<div className="day-row-text">
												<span>2.00 pm – 4:00 pm</span>
												<span> | </span>
												<span>Amphitheatre</span>
											</div>
										</div>
										<div className="day-row-main-head">
												<div className="day-row-sub-head">Workshop 02</div>
										</div>
								</div>
						</div>
						
				</div>
		</div>
);

export default DayOneContent;
