const day2 = {
    morning: [
      { 
        id: "seminar-on-pedagogy",
        name: "SEMINAR on PEDAGOGY",
        items: [
          { id: "general",
            name: "",
            subItems: [
              { title: "Keynote Speech", desc: "Accessibility and Sustainable Education: Models for Action" },
              { title: "Panel Discussion I", desc: "Landscapes of Imagination: Creative and Practical Approaches to Arts Education" },
              { title: "Panel Discussion II", desc: "Museum Education and School Education: Necessity of Convergence" },
            ]
          }
        ]
      },
      {
        id: "lab1",
        name: "LAB 1 | Art1st Partner-a-Master",
        items: [
          {
            id: "module1",
            name: "",
            subItems: [
              { title: "Module 1", desc: "Comics Making Workshop with Art Teachers with Lokesh Khodke" }
            ]
          }
        ]
      },
      {
        id: "lab2",
        name: "LAB 2 | Build your Own Lab",
        items: [
          {
            id: "module-full-day",
            isFullDay: true,
            name: "",
            subItems: [
              { title: "Module (Full Day Session)", desc: "Build your own Microscope" }
            ]
          }
        ]
      },
      {
        id: "lab3",
        name: "LAB 3 | Culture and Museum Learning",
        items: [
          {
            id: "module1",
            name: "",
            subItems: [
              { title: "Module 1", desc: "Museum of Idea and Objects" }
            ]
          }
        ]
      },
      {
        id: "lab4",
        name: "LAB 4 | Creative Learning and Practice (CLaP)",
        items: [
          {
            id: "module1",
            name: "",
            subItems: [
              { title: "Module 1", desc: "Subject-oriented lessons" }
            ]
          }
        ]
      },
      {
        id: "lab5",
        name: "LAB 5 | UNBOX: An approach to Design Thinking",
        items: [
          {
            id: "module1",
            name: "",
            subItems: [
              { title: "Module 1", desc: "Changing perceptions: A Teacher’s guide to Design thinking with TinkerLabs" }
            ]
          }
        ]
      }
    ],
    afternoon: [
      { 
        id: "seminar-on-pedagogy",
        name: "SEMINAR on PEDAGOGY",
        items: [
          { id: "general",
            name: "",
            subItems: [
              { title: "Paper Presentation I", desc: "Intersections of Museum Learning, Arts Thinking and Educational Psychology in Last Two Decades" },
              { title: "Paper Presentation II", desc: "Art, Ecology and Everyday Life: Creating Curriculums for Young Minds (EKW Project)" },
              { title: "Best Practices: Case Studies", desc: "Slam Out Loud" },
              { title: "Best Practices: Case Studies", desc: "Problem Se No Problem" },
              { title: "Thematic Workshop", desc: "Creating Living Curriculums for Schools: Engaging with the Social" }
            ]
          }
        ]
      },
      {
        id: "lab1",
        name: "LAB 1 | Art1st Partner-a-Master",
        items: [
          {
            id: "module2",
            name: "",
            subItems: [
              { title: "Module 2", desc: "Via the Museum 1: Concept and Collection: Art Education in the Museums with Komal Pande" }
            ]
          }
        ]
      },
      {
        id: "spacer",        
        items: [
          { id: "spacer" }
        ]
      },
      {
        id: "lab3",
        name: "LAB 3 | Culture and Museum Learning",
        items: [
          {
            id: "module2",
            name: "",
            subItems: [
              { title: "Module 2", desc: "Museum of Idea and Objects" }
            ]
          }
        ]
      },
      {
        id: "lab4",
        name: "LAB 4 | Creative Learning and Practice (CLaP)",
        items: [
          {
            id: "module2",
            name: "",
            subItems: [
              { title: "Module 2", desc: "Emo-Totems" }
            ]
          }
        ]
      },
      {
        id: "lab5",
        name: "LAB 5 | UNBOX: An approach to Design Thinking",
        items: [
          {
            id: "module2",
            name: "",
            subItems: [
              { title: "Module 2", desc: "Changing perceptions: A Teacher’s guide to Design thinking with TinkerLabs" }
            ]
          }
        ]
      }
    ]
  };

  export default day2;