const day3 = {
    morning: [
      { 
        id: "seminar-on-advocacy",
        name: "SEMINAR on ADVOCACY",
        items: [
          { id: "general",
            name: "",
            subItems: [
              { title: "Keynote Address", desc: "Leading Education Curriculums" },
              { title: "Panel Discussion I", desc: "Affective Pedagogy and Emotional Nurturing through the Arts: A Child-Centric Approach " },
              { title: "Panel Discussion II", desc: "Collaborative Models and Action Research in Schools" },
            ]
          }
        ]
      },
      {
        id: "lab1",
        name: "LAB 1 | Art1st Partner-a-Master",
        items: [
          {
            id: "module1",
            name: "",
            subItems: [
              { title: "Module 1", desc: "Via the Museum 2: Context and Content: Possibilities of Curation with Komal Pande" }
            ]
          }
        ]
      },
      {
        id: "lab2",
        name: "LAB 2 | Build your Own Lab",
        items: [
          {
            id: "module-full-day",
            name: "",
            isFullDay: true,
            subItems: [
              { title: "Module (Full Day Session)", desc: "Build your own Pollution Sensor" }
            ]
          }
        ]
      },
      {
        id: "lab3",
        name: "LAB 3 | Culture and Museum Learning",
        items: [
          {
            id: "module1",
            name: "",
            subItems: [
              { title: "Module 1", desc: "Museum of Idea and Objects" }
            ]
          }
        ]
      },
      {
        id: "lab4",
        name: "LAB 4 | Creative Learning and Practice (CLaP)",
        items: [
          {
            id: "module1",
            name: "",
            subItems: [
              { title: "Module 1", desc: "Mapping Everyday Environments" }
            ]
          }
        ]
      },
      {
        id: "lab5",
        name: "LAB 5 | UNBOX: An approach to Design Thinking",
        items: [
          {
            id: "module1",
            name: "",
            subItems: [
              { title: "Module 1", desc: "Creative Confidence through Design Thinking with D-Van" }
            ]
          }
        ]
      }
    ],
    afternoon: [
      { 
        id: "seminar-on-advocacy",
        name: "SEMINAR on ADVOCACY",
        items: [
          { id: "general",
            name: "",
            subItems: [
              { title: "Paper Presentation I", desc: "Art and the Republican Universe: The Modern School, Delhi, 1947-1961" },
              { title: "Paper Presentation II", desc: "Relevance of Collaborative Art Projects in the Arts Education System" },
              { title: "Best Practices", desc: "Approaching Art education through Engagement and Interaction: The Art1st Way " },
              { title: "Launch", desc: "Tribute to Tushar Joag / Launch of Awards for Best Action Research" },
              { title: "Art Education Roundtables (AER) White Paper Release 2019", desc: "Translating the AER White Paper into Policy and Action" },
            ]
          }
        ]
      },
      {
        id: "lab1",
        name: "LAB 1 | Art1st Partner-a-Master",
        items: [
          {
            id: "module2",
            name: "",
            subItems: [
              { title: "Module 2", desc: "Session with Elke Zobl" }
            ]
          }
        ]
      },
      {
        id: "spacer",
        items: [
          { id: "spacer" }
        ]
      },
      {
        id: "lab3",
        name: "LAB 3 | Culture and Museum Learning",
        items: [
          {
            id: "module2",
            name: "",
            subItems: [
              { title: "Module 2", desc: "Museum of Idea and Objects" }
            ]
          }
        ]
      },
      {
        id: "lab4",
        name: "LAB 4 | Creative Learning and Practice (CLaP)",
        items: [
          {
            id: "module2",
            name: "",
            subItems: [
              { title: "Module 2", desc: "Future paths and choosing a “career“" }
            ]
          }
        ]
      },
      {
        id: "lab5",
        name: "LAB 5 | UNBOX: An approach to Design Thinking",
        items: [
          {
            id: "module2",
            name: "",
            subItems: [
              { title: "Module 2", desc: "Creative Confidence through Design Thinking with D-Van" }
            ]
          }
        ]
      }
    ]
  };

  export default day3;