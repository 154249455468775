import React from 'react';
import { Link } from "react-router-dom";

import './Teachers.less';
import TeachersIcon from 'assets/Group 72@2x.png';

const Teachers = () => (
    <div className="teachers-ctr">
        <div className="teachers-content">
            <div className="teachers-content-left">
                <h3 className="teachers-heading">For Teachers</h3>
                <div className="teachers-text">
                    Engage with experts, upgrade your classrooms and elevate your teaching practices.
                </div>
                <div className="teachers-redirect">
                    <Link to="/ilabs">Interactive Labs</Link>
                </div>
            </div>
            <div className="teachers-content-right">
                <img alt="For Teachers" src={TeachersIcon} />
            </div>
        </div>
    </div>
);

export default Teachers;
