import React from 'react';
import ModuleItems from './ModuleItems';

const SessionRows = (props) => (
    // <tr>
    // <td className="session-name" key={props.morningItem.id}>{props.morningItem.name}</td>
    <>
    { props.morningItem.items.map((currentItem, idx) => (
        <tr key={`${currentItem.id}-${idx}`}>
            <td className="session-name" key={props.morningItem.id}>{props.morningItem.name}</td>
            <ModuleItems 
                currentItem={currentItem} 
                clickHandler={props.handler} 
                day={props.day} 
                slot="morning"
                seats={props.slots}
                session={props.morningItem.id} />
            <ModuleItems 
                currentItem={props.afternoonItem.items[idx]} 
                clickHandler={props.handler} 
                day={props.day}
                seats={props.slots}
                isDayFull={props.isDayFull} 
                session={props.morningItem.id}
                slot="afternoon" /> 
        </tr>
        )) 
    }
    </>
);

export default SessionRows;
