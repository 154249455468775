import React from 'react';

import ILabsContentImage1 from 'assets/64303958_2256815707748007_3461965883658731520_o.jpg';
import ILabsContentImage2 from 'assets/ArtScienceBLR.png';
import ILabsContentImage3 from 'assets/FlowIndia_Logo-01.png';
import ILabsContentImage4 from 'assets/logo-lowres.svg';

const ILabsContent = () => (
    <div className="ilabs-content-ctr">
		<div className="ilabs-content">
			<div className="ilabs-content-text">
				<p>
					Participate in the Interactive Labs to interact with experts and innovators in the space. Collaborate, innovate and experiment with them to transform curriculums and elevate teaching practices. These intensive sessions cover a range of topics from cross-disciplinary collaborations, human-centered design, cultural sustainability and applied imagination with the best and the brightest in the field. 
				</p>
				<p>
					Each lab will have between 20-30 participants who will be expected to interact, push past their comfort zones, as they develop ideas and modules for their school curricula related to art, science, technology, community art, design thinking, waste, and sustainability in order to nurture young minds.
				</p>
				<p>
					Read the details below and select which Labs you'd like to participate in.
				</p>
			</div>
		</div>
	</div>
);

export default ILabsContent;
