import React from 'react';

import './Contact.less';

const Contact = () => {
    return (
        <div className="contact-info-ctr">
            <h3 className="contact-info-title">Contact</h3>
            <div className="contact-info-sub">For queries, email us: artsummit@art1st.co.in </div>
            <div className="contact-info-desc">
                <div className="contact-info-bold-text">For Registrations, Volunteering, BookSales</div>
                <div className="contact-info-text">
                    <div className="contact-info-text1">
                        Priyam Mehta
                    </div>
                    <div className="contact-info-text2">
                        Call: +91 9820754744 | +91 9987962986
                    </div>
                    <div className="contact-info-text3">
                        Email: priyam@art1st.co.in
                    </div>
                </div>
                <div className="contact-info-bold-text">For Venue and Directions</div>
                <div className="contact-info-text">
                    <div className="contact-info-text1">
                        Abhinav Yagnik
                    </div>
                    <div className="contact-info-text2">
                        Call: +91 8291192654 / +91 9643063594
                    </div>
                    <div className="contact-info-text3">
                        Email: yagnik.abhinav@gmail.com
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
