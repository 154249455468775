import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome} from '@fortawesome/free-solid-svg-icons';

import './Header.less';
import ImgArtEdLogo from 'assets/Art Education Summit Logo_Final-13@2x.png';

const Header = () => (
    <header className="header-ctr header-mobile-ctr">
	<nav role="navigation">
		<div className="header-content">
			<div className="header-logo">
				<a href="/">
					<img src={ImgArtEdLogo} alt="Art Education Summit" />
				</a>
			</div>
			<div id="menuToggle">
				<input type="checkbox" />
				<span></span>
				<span></span>
				<span></span>

				<ul id="menu">
					<li>
							<NavLink to="/schedule" className="normal" activeClassName="active" exact>Schedule</NavLink>
					</li>
					<li>
							<NavLink to="/speakers" className="normal" activeClassName="active" exact>Speakers</NavLink>
					</li>
					<li>
							<NavLink to="/ilabs" className="normal" activeClassName="active" exact>Interactive Labs</NavLink>
					</li>
					<li>
							<NavLink to="/childartmuseum" className="normal" activeClassName="active" exact>Child Art Museum</NavLink>
					</li>
					<li>
							<NavLink to="/about" className="normal" activeClassName="active" exact>About</NavLink>
					</li>
					<li>
							<NavLink to="/help" className="normal" activeClassName="active" exact>FAQs</NavLink>
					</li>
					<li className="register-btn">
							<a href="https://forms.gle/Xvoc3GmEkxkA5Cis6" target="_blank">REGISTER</a>
					</li>
				</ul>
			</div>
		</div>
	</nav>
	</header>
);

export default Header;
