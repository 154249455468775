import React from 'react';
import { Link } from "react-router-dom";

import './Organizers.less';
import ArtistLogo from 'assets/Art1st Logo-01@2x.png';
import IGNCALOGO from 'assets/IGNCA LOGO New@2x.png';
import CBSELOGO from 'assets/cbse-logo@2x.png';
import SupportersImage1 from 'src/assets/Organizers/Supported By/FlowIndia_Logo-01.png';
import SupportersImage2 from 'src/assets/Organizers/Supported By/HEXAWARE LOGO.png';
import SupportersImage3 from 'src/assets/Organizers/Supported By/KNMA Logo.png';
import SupportersImage4 from 'src/assets/Organizers/Supported By/spf_fin.jpg';

const Organizers = () => (
    <div className="organizers-ctr">
        <div className="organizers-title">
            Brought to you by
        </div>
        <div className="organizers-content">
            <div className="icon-1">
                <img src={ArtistLogo} alt="Artist Logo" />
            </div>
            <div className="icon-2">
                <img src={IGNCALOGO} alt="IGNCA LOGO" />
            </div>
            <div className="icon-2">
                <img src={CBSELOGO} alt="cbse logo" style={{height: 150, width: 150}} />
            </div>
        </div>
        {/* <div className="organizers-title">Supported by</div>
        <div className="organizers-content supporters-content">
            <div className="icon-1">
                    <img src={SupportersImage3} alt="Artist Logo" />
            </div>
            <div className="icon-2">
                    <img src={SupportersImage1} alt="IGNCA LOGO" />
            </div>
            <div className="icon-2">
                    <img src={SupportersImage4} alt="cbse logo" />
            </div>
            <div className="icon-2">
                    <img src={SupportersImage2} alt="cbse logo" />
            </div>
        </div> */}
    </div>
);

export default Organizers;
