import React, { Component } from 'react';

import './Select.less';

class Option extends Component{

		constructor(props){
				super(props);
				this.onSelect = this.onSelect.bind(this);
		}

		onSelect(e){
				e.preventDefault();
				this.props.onSelect(this.props.option);
		}

		render(){
				return (
						<li><a href="#" onClick={this.onSelect}>{this.props.option.value}</a></li>
				);
		}
}

export default Option;
