import React from 'react';

import './Intro.less';
import Intro1 from 'assets/IGNCA LOGO New.png';
import Intro2 from 'assets/Art1st Logo-01.png';
import Intro3 from 'assets/cbse-logo.png';

const Intro = () => (
    <div className="intro-ctr">
        <div className="intro-content">
            <div className="intro-logo-content">
                <div className="logo-1">
                    <img src={Intro2} alt="Art Education Summit" />
                </div>
                <div className="logo-2">
                    <img src={Intro1} alt="Art Education Summit" />
                </div>
                <div className="logo-3">
                    <img src={Intro3} alt="Art Education Summit" />
                </div>
            </div>
            <div className="intro-text">
                The Art Education Summit 2019 is presented by Art1st Foundation in collaboration with
                Indira Gandhi National Centre for the Arts(IGNCA) and Central Board of Secondary Education(CBSE).
            </div>
        </div>
    </div>
);

export default Intro;
