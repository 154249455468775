import React from 'react';

import './Footer.less';
import ImgFB from 'assets/fb@2x.png';
import ImgTwitter from 'assets/twitter@2x.png';
import ImgInstagram from 'assets/insta@2x.png';
import ImgYT from 'assets/yt@2x.png';
import ImgArt1st from 'assets/Art1st.png';

const Footer = () => (
    <div className="footer-ctr">
        <div className="footer-content">
            <div className="footer-mid-content">
                <div className="footer-content-top">
                    <img src={ImgArt1st} alt="Art1st" />
                    <a href="www.art1st.co.in" target="_blank" rel="noopener noreferrer">www.art1st.co.in</a>
                </div>
                <div className="footer-content-bottom">
                    <a href="https://www.facebook.com/art1stIndia/" target="_blank"><img src={ImgFB} alt="Facebook" /></a>
                    <a href="https://www.youtube.com/channel/UCVpcSbA8ZuzN7oiIB_3ZQLw" target="_blank"><img src={ImgYT} alt="Youtube" /></a>
                    <a href="https://www.instagram.com/art1stindia" target="_blank"><img src={ImgInstagram} alt="Instagram" /></a>
                    <a href="https://www.twitter.com/art1stindia" target="_blank"><img src={ImgTwitter} alt="Twitter" /></a>
                </div>
            </div>
        </div>
    </div>
);

export default Footer;
