import React from 'react';
import { Link } from "react-router-dom";

import './InteractiveLabs.less';
import InteractiveLabsLogo1 from 'src/assets/Interactive Labs Graphics-01@2x.png';
import InteractiveLabsLogo2 from 'src/assets/Interactive Labs Graphics-02@2x.png';
import InteractiveLabsLogo3 from 'src/assets/Group 124@2x.png';
import InteractiveLabsLogo4 from 'src/assets/Interactive Labs Graphics-04@2x.png';
import InteractiveLabsLogo5 from 'src/assets/Interactive Labs Graphics-05@2x.png';

const InteractiveLabs = () => (
    <div className="interactive-labs-ctr">
        <div className="interactive-labs-heading">
            Interactive Labs
        </div>
        <div className="interactive-labs-content">
            <div className="interactive-labs-items">
                <div className="interactive-labs-item">
                    <div className="interactive-labs-avatar">
                        <img src={InteractiveLabsLogo1} />
                    </div>
                    <div className="interactive-labs-title">
                        Build Your Own Lab
                    </div>
                    <div className="interactive-labs-role">
                        by Art Science BLR
                    </div>
                </div>
                <div className="interactive-labs-item">
                    <div className="interactive-labs-avatar">
                        <img src={InteractiveLabsLogo2} />
                    </div>
                    <div className="interactive-labs-title">
                        Cultural & Museum Learning
                    </div>
                    <div className="interactive-labs-role">
                        by FLOW India
                    </div>
                </div>
                <div className="interactive-labs-item">
                    <div className="interactive-labs-avatar">
                        <img src={InteractiveLabsLogo3} />
                    </div>
                    <div className="interactive-labs-title">
                        Culture Connectors
                    </div>
                    <div className="interactive-labs-role">
                        by FLOW India
                    </div>
                </div>
                <div className="interactive-labs-item">
                    <div className="interactive-labs-avatar">
                        <img src={InteractiveLabsLogo4} />
                    </div>
                    <div className="interactive-labs-title">
                        Creative Learning and Practice (CLaP)
                    </div>
                    <div className="interactive-labs-role">
                        by CASP
                    </div>
                </div>
                <div className="interactive-labs-item">
                    <div className="interactive-labs-avatar">
                        <img src={InteractiveLabsLogo5} />
                    </div>
                    <div className="interactive-labs-title">
                        Partner a Master:<br /> Artist-Mentor Program
                    </div>
                    <div className="interactive-labs-role">
                        by contemporary artists
                    </div>
                </div>
            </div>
            <div className="interactive-labs-note">
                <div className="bold-text">Participate in our innovative and hands-on Interactive Labs</div>
                <div className="text"><Link to="/ilabs">Read More</Link></div>
            </div>
        </div>
    </div>
);

export default InteractiveLabs;
