import React from 'react';

import AboutImage1 from 'assets/about/about1.png';
import AboutImage2 from 'assets/about/about2.png';
import AboutImage3 from 'assets/about/about3.png';

const AboutContent = () => (
    <div className="about-content-ctr">
				<div className="about-content">
					<p>
						We believe that the arts are at the core to create empathetic minds and philosophical souls to grasp current
						issues of our world and offer constructive solutions for future living. For this, our children need to be at
						the centre of our policy making, we require to move beyond the instrumental modes in the education sector,
						and critically assess problems that inhibit creativity in schools to ignite a sense of autonomy and freedom
						of thought and action.
					</p>
					<p>
							In 2019, Art1st Foundation launched the Art Education Roundtables (AER) to understand the present scenario
							of art education in India, mobilizing discussions and active interactions with stakeholders in both metros
							and two-tier cities. The aim of the AER is to collect thoughts, ideas, and outlooks to collectively chart
							out a new direction for art pedagogy and cultural policy in India and bridge the gap in the education system.
							The AERs have been implemented in New Delhi, Kolkata, Baroda, Chennai, Bhubaneswar, and Mumbai and Guwahati.
					</p>
					<div className="about-content-image-1">
						<img src={AboutImage1} alt="About" className="full-width-img" />
					</div>
					<div className="about-content-image-2">
						<img src={AboutImage2} alt="About"/>
						<img src={AboutImage3} alt="About"/>
					</div>
					<p>
						These roundtables culminate in the <b>Art Education Summit</b> from <b>December 18-20, 2019</b> in
						collaboration with the <b>Indira Gandhi National Centre for the Arts (IGNCA)</b> and the
						<b> Central Board of Secondary Education (CBSE),</b> New Delhi, India. The goal is to address the areas of policy,
						pedagogy and advocacy through absorbing keynote addresses, panels, paper presentations,
						case studies, thematic workshops, interactive labs, children’s workshops, a child art exhibition and walkthroughs.
					 A major publication will be the outcome of this summit, which will offer reflective and action-oriented perspectives on the
					 transformative role of the arts in education.
					</p>

					<p>
						Apart from these summit modules, Art1st Foundation will present a <b>White Paper</b>, based on its research,
						survey and learning from the AERs in seven cities. This will be used as a blueprint to emphasize on the necessity
						of arts education and its implementation, and will be circulated with the cultural ministry, educational
						institutions and research experts. The summit will also announce the Tushar Joag Awards for art teachers,
						as a tribute to the noted artist and pedagogue, Tushar Joag (1966-2018), who was an advisory member at Art1st Foundation
						and shaped its vision intrinsically.
					</p>
				</div>
		</div>
);

export default AboutContent;
